import React from 'react';
import { Text } from 'rebass/styled-components';
import { Alert, Transaction } from '@providers/types';
import { EmployeeDetail } from './EmployeeDetail';
import { currencyFormat } from '@utils/formatting';

interface AlertDetailProps {
    transaction: Transaction;
    alert: Alert;
}

export const GenericAlert = ({ transaction, alert }: AlertDetailProps) => {
    return <>
        <Text fontSize={18} pt={20} minHeight={96}>
            {transaction.payrollName}
        </Text>
        <Text fontSize={18} pt={20} minHeight={96}>
            {`Gross: ${currencyFormat(transaction.payStatementGross)}`}
        </Text>
        <Text fontSize={18} pt={20} minHeight={96}>
            {`Net: ${currencyFormat(transaction.payStatementNet)}`}
        </Text>
        <EmployeeDetail alert={alert} />
    </>;
};
