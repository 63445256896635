import React from 'react';
import { Flex } from 'rebass/styled-components';
import { Label, Select } from '@rebass/forms';

interface TableSizeSelectorProps {
    pageSize: number;
    dataSize: number;
    onChangePageSize: (pageSize: number) => void;
}

const PAGE_SIZE = {
    S: 10,
    M: 30,
    L: 50,
};

export const TableSizeSelector = ({ pageSize, dataSize, onChangePageSize }: TableSizeSelectorProps) =>
    <Flex alignItems='center'>
        <Label width={70} htmlFor='rows'># Rows</Label>
        <Select width={120} bg='white'
            id='rows'
            name='rows'
            value={pageSize}
            onChange={({ target: { value } }) => (onChangePageSize(+value))}>
            {Object.entries(PAGE_SIZE).map(([key, size]: [string, number]) => (
                <option key={key}
                    value={size}>
                    {size}
                </option>
            ))}
        </Select>
        <Label mx={3} width={80}> total: {dataSize} </Label>
    </Flex>;
