import React from 'react';
import { Text } from 'rebass/styled-components';
import { Alert, defaultUseGetTransactionsFields } from '@providers/types';
import { useGetTransactions } from '@providers/transaction';
import { QueryPathFields } from '@providers/apiProvider';
import { TransactionList } from '@components/transaction/TransactionList';

import { AlertTypeName } from './AlertTypes';

import { AlertTypeMaxPerPayment } from './transactions/AlertTypeMaxPerPayment';
import { AlertTypeEmployeeReceivedTooManyPayments } from './transactions/AlertTypeEmployeeReceivedTooManyPayments';
import { AlertTypeCompanyPayrollIncremented } from './payrolls/AlertTypeCompanyPayrollIncremented';
import { AlertTypeEmployeePaymentMoreThanMovingAverage } from './transactions/AlertTypeEmployeePaymentMoreThanMovingAverage';

import { AlertTypeInvalidLicense } from './components/AlertTypeInvalidLicense';
import { AlertTypeDetailWrapper } from './components/AlertTypeDetailWrapper';
import { GenericAlert } from './components/GenericAlert';

interface AlertTypeDetailProps {
    alert: Alert;
    query?: QueryPathFields;
    updateQuery?: (params: QueryPathFields) => void;
}

export const AlertTypeDetail = ({ alert, query, updateQuery }: AlertTypeDetailProps): JSX.Element => {
    const { data: transactions } = useGetTransactions({
        ...defaultUseGetTransactionsFields,
        alertId: alert.id, page: 1, pageSize: 1,
    });

    switch (alert.alertType.name) {
        case AlertTypeName.PaymentOver20K:
            return <AlertTypeMaxPerPayment alert={alert} query={query}
                updateQuery={updateQuery} amount={20000} />;
        case AlertTypeName.PaymentOver30K:
            return <AlertTypeMaxPerPayment alert={alert} query={query}
                updateQuery={updateQuery} amount={30000} />;
        case AlertTypeName.EmployeeReceivedMoreThan5PaymentsMonth:
            return <AlertTypeEmployeeReceivedTooManyPayments alert={alert} max_payments={5}
                query={query} updateQuery={updateQuery} />;
        case AlertTypeName.CompanyPayrollIncremented30:
            return <AlertTypeCompanyPayrollIncremented alert={alert} percent={30}
                query={query} updateQuery={updateQuery} />;
        case AlertTypeName.EmployeePaid30MoreThanAverage:
            return <AlertTypeEmployeePaymentMoreThanMovingAverage alert={alert} percent={30} diminimum={2000}
                query={query} updateQuery={updateQuery} />;
        case AlertTypeName.EmployeePaid50MoreThanAverage:
            return <AlertTypeEmployeePaymentMoreThanMovingAverage alert={alert} percent={50} diminimum={5000}
                query={query} updateQuery={updateQuery} />;
        case AlertTypeName.InvalidLicense:
            return <AlertTypeInvalidLicense alert={alert} />;
        default:
            return <>{transactions && transactions.data && transactions.data.length > 0 &&
                <AlertTypeDetailWrapper payStatementGross={transactions.data[0].payStatementGross}
                    previousGrossNet={transactions.data[0].previousGrossNet} alertTypeDetail={
                        <GenericAlert transaction={transactions.data[0]} alert={alert} />
                    }>
                    <Text fontSize={38}>Transaction History</Text>
                    <TransactionList query={query} updateQuery={updateQuery} />
                </AlertTypeDetailWrapper>}</>;
    }
};
