import React from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { PostComment } from '@components/comment/PostComment';
import { EscalateButton } from '@components/comment/EscalateButton';
import { AlertResolve } from './AlertResolve';
import { Alert, StatusEnum } from '@providers/types';
import { Tooltip, TooltipModeEnum, TooltipPositionEnum } from '@components/tooltip';
import { AlertResolveWhitelist } from './AlertResolveWhitelist';

const AlertOptionsFooter = ({ alert, setForceUpdate }:
    { alert: Alert, setForceUpdate: () => void }): JSX.Element =>
    <Flex p={2} justifyContent='flex-end'>
        <EscalateButton alert={alert} onRefreshAlert={setForceUpdate} />
        <PostComment alertId={alert.id} setForceUpdate={setForceUpdate} />
        {(alert.status === StatusEnum.OPEN) &&
            <Tooltip visible={alert && alert.user ? false : true}
                content={<Text m='20px' maxWidth='280px' textAlign='center'>
                    Alert must be assigned to a user before it can be resolved
                </Text>}
                mode={TooltipModeEnum.HOVERED} position={TooltipPositionEnum.TOP}>
                <AlertResolveWhitelist alert={alert} setForceUpdate={setForceUpdate} />
                <AlertResolve alert={alert} setForceUpdate={setForceUpdate} />
            </Tooltip>
        }
    </Flex>;

export { AlertOptionsFooter };
