import { StyledLink } from '@components/styles/link.styled';
import React from 'react';

interface EmployeeLinkProps {
    id?: string;
    name?: string;
}

const EmployeeLink = ({ id, name }: EmployeeLinkProps) => <b><StyledLink to={`/employees/${id}`}>
    {name}
</StyledLink></b>;

export { EmployeeLink };
