import React, { useRef, useEffect } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { ModalContainer, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from './components/modal.styled';
import { ModalProps } from './types/modal.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ visible, setVisible, header, children, footer }: ModalProps) => {
    const node = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (event: MouseEvent | React.MouseEvent<HTMLDivElement>) => {
        const { target } = event as React.MouseEvent<HTMLDivElement>;
        if (node && node.current && node.current.contains(target as Node)) {
            return;
        }
        handleVisible(false);
    };

    const handleVisible = (visible: boolean) => {
        if (visible) {
            window.addEventListener('click', handleOutsideClick);
        } else {
            window.removeEventListener('click', handleOutsideClick);
        }
        setVisible(visible);
    };

    useEffect(() => {
        handleVisible(visible);
    }, [visible]);

    return (<ModalContainer visible={visible}>
        <ModalContent ref={node}>
            <ModalHeader alignItems='center'>
                <Box width='80%'>
                    {header}
                </Box>
                <Box ml='auto'>
                    <ModalCloseButton onClick={() => handleVisible(!visible)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </ModalCloseButton>
                </Box>
            </ModalHeader>
            <ModalBody flexDirection='column'>
                {children}
            </ModalBody>
            <Flex p={3} flexWrap='wrap' alignItems='center' justifyContent='center'>
                {footer}
            </Flex>
        </ModalContent>
    </ModalContainer>);
};

export { Modal };
