import React, { useEffect, useState } from 'react';
import { AlertList } from '@components/alert/AlertList';
import { InlineText } from '@components/styles/text.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Box } from 'rebass/styled-components';
import { useHistory } from 'react-router-dom';
import { getObjFromQuery, getRawQuery } from '@utils/queryParams';
import { defaultUseGetAlertsFields } from '@providers/types';

const AlertsList = (): JSX.Element => {
    const history = useHistory();
    const [query, setQuery] = useState({
        ...(history.location.search ?
            getObjFromQuery(history.location.search) : defaultUseGetAlertsFields)
    });
    useEffect(() => {
        history.push(`${history.location.pathname}?${getRawQuery(query)}`);
    }, [query]);

    return (
        <section>
            <Box>
                <InlineText fontSize={27} ><FontAwesomeIcon icon={faExclamationTriangle} /></InlineText>
                <InlineText fontSize={43}> Alerts </InlineText>
            </Box>
            <AlertList query={query} updateQuery={setQuery} />
        </section>
    );
};


export default AlertsList;
