import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { RiskIcon } from '@components/alert/components/RiskIcon';
import { StatusIcon } from '@components/alert/components/StatusIcon';
import { ColumnFormatter, ColumnFormatterDefaultValues } from '@components/table/TableHeader';

interface AlertColumnFormatter extends ColumnFormatter {
    alertTypeFormatter: ((value: { [key: string]: string }) => string);
    genericFormatter: ((value?: { [key: string]: string }) => JSX.Element);
    riskFromatter: ((value: string) => JSX.Element);
    statusFromatter: ((value: string, fontSize?: number) => JSX.Element);
}

export const AlertColumnFormatterDefinition: AlertColumnFormatter = {
    alertTypeFormatter: (value: { [key: string]: string }): string =>
        value.name,
    genericFormatter: (value?: { [key: string]: string }): JSX.Element =>
        !value ? <FontAwesomeIcon title={value} icon={faMinus} /> :
            <>{value.name}</>,
    riskFromatter: (value: string): JSX.Element => <RiskIcon value={value} />,
    statusFromatter: (value: string, fontSize?: number): JSX.Element =>
        <StatusIcon value={value} size={fontSize !== undefined ? `${fontSize}px` : undefined} />,
    ...ColumnFormatterDefaultValues,
};
