import styled from 'styled-components';
import { Text, Heading } from 'rebass/styled-components';

export const InlineText = styled(Text)`
    display: inline-flex;
    padding: 0 0 0 10px;
`;

export const InlineHeading = styled(Heading)`
    display: inline-flex;
    padding: 0 0 0 10px;
`;
