import { useFetchAuthedApi, AuthedApiResult, QueryPathFields } from './apiProvider';
import { Company } from './types';
import { getRawQuery } from '@utils/queryParams';

export const useGetCompanies = (params: QueryPathFields): AuthedApiResult<{ data: Company[], total: number }> => {
    return useFetchAuthedApi<{ data: Company[], total: number }>({
        path: `companies?${getRawQuery(params)}`,
    });
};

export const useGetCompany = (companyId: string): AuthedApiResult<Company> => {
    return useFetchAuthedApi<Company>({ path: `companies/${companyId}` });
};

export const useCountCompanyAlerts = (companyId: string): AuthedApiResult<{ openAlerts: number }> => {
    return useFetchAuthedApi<{ openAlerts: number }>({ path: `companies/${companyId}/alerts/count` });
};
