import React from 'react';
import { Alert } from '@providers/types';
import { AlertTypeDetailWrapper } from '../components/AlertTypeDetailWrapper';
import { QueryPathFields } from '@providers/apiProvider';
import { PayrollList } from '@components/payroll/PayrollList';
import moment from 'moment';
import { Text } from 'rebass/styled-components';
import { currencyFormat } from '@utils/formatting';
import { highlightAs, HighlightColor } from '@components/table/TableContent';

interface AlertDetailProps {
    alert: Alert;
    percent: number;
    query?: QueryPathFields;
    updateQuery?: (params: QueryPathFields) => void;
}

export const AlertTypeCompanyPayrollIncremented = ({ alert,
    percent, query, updateQuery }: AlertDetailProps) => {

    const percentIncrement = (alert.alertDetails.paid_today / alert.alertDetails.last_two_months_average * 100) - 100;

    return <AlertTypeDetailWrapper payStatementGross={alert.alertDetails.paid_today}
        previousGrossNet={alert.alertDetails.last_two_months_average} alertTypeDetail={
            <Text fontSize={18} pt={20} minHeight={96}>
                On <b>{moment(alert.createdDate).format('MMMM DD, YYYY')}</b>,
                the system detected that
                the company <b>{alert.companyName}</b> {' '}
                has increased the payroll <b>{percentIncrement.toFixed(2)}%</b>,
                from an average of <b>{currencyFormat(alert.alertDetails.last_two_months_average)}</b>,
                to a total of <b>{currencyFormat(alert.alertDetails.paid_today)}</b>,
                thus going over the threshold of <b>{percent}%</b>.
            </Text>
        }>
        <PayrollList
            query={query}
            updateQuery={updateQuery}
            highlightedPayrolls={{
                ...highlightAs(alert.alertDetails.last_two_months_payrolls, HighlightColor.YELLOW),
                ...highlightAs(alert.alertDetails.todays_payrolls, HighlightColor.RED),
            }}
        />
    </AlertTypeDetailWrapper>;
};
