import React from 'react';
import { Flex, Box, Heading } from 'rebass/styled-components';
import { InlineText } from '@components/styles/text.styled';
import { dateFormat } from '@utils/formatting';
import { Comment } from '@providers/types';
import { CommentContainer } from './components/commentsList';

const CommentMeta : React.FC<{}> = ({children}): React.ReactElement => (
  <Box p={0.5} fontSize={'0.8em'} css='font-style: italic;'>
    {children}
  </Box>
);

const ShowComment : React.FC<{ comment: Comment }> = ({comment}) => (
  <Flex flexDirection='column' width={1} key={comment.id} mb={20}>
      <Box>
          <InlineText fontWeight='bold' pr={2}>{comment.createdBy.name}</InlineText> |
          <InlineText>Dated {dateFormat(comment.createdDate || '')}</InlineText>
      </Box>
      <CommentContainer>
          <Flex flexDirection='column' width={1}>
              { comment.new_status &&
                <CommentMeta>Status changed to {comment.new_status}</CommentMeta> }
              { comment.new_riskLevel &&
                <CommentMeta>Priority changed to {comment.new_riskLevel}</CommentMeta> }
              { comment.new_user &&
                <CommentMeta>Reassigned to {comment.new_user.name} ({comment.new_user.id})</CommentMeta> }
              {comment.title && <Box p={2}>
                  <InlineText fontWeight='bold'>{comment.title}</InlineText>
              </Box>}
              <Box p={2}>
                  {comment.body}
              </Box>
          </Flex>
      </CommentContainer>
  </Flex>
);


const CommentsList = ({ comments }: { comments: Comment[] }): React.ReactElement => {
    return (comments.length ?
        <Flex flexDirection='column' backgroundColor='background' px={20}
            sx={{ border: '1px solid #dddddd' }}>
            <Heading fontSize={38} mb={20} mt={10}>Comments</Heading>
            {comments.map(comment => <ShowComment comment={comment} />)}
        </Flex> : <Heading>No comments</Heading>);
};

export { CommentsList };
