import styled from 'styled-components';
import { Flex } from 'rebass/styled-components';

export const DropZoneContainer = styled(Flex)`
    flex-direction: column;
    align-items: center;
    border-width: 2;
    border-radius: 2;
    height: 100%;
    border-color: ${({ theme }) => theme.colors.gray};
    background-color: ${({ theme }) => theme.colors.muted};
    color: ${({ theme }) => theme.colors.text};
    border-style: dashed;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
`;
