import React from 'react';
import TableComponent from '@components/table/Table';
import { QueryPathFields } from '@providers/apiProvider';
import { useGetEmployees } from '@providers/employee';
import { useHistory } from 'react-router-dom';
import { ColumnProps } from '@components/table/TableHeader';
import { TableFilterTypeEnum } from '@components/table/tableFilter';

interface EmployeeListProps {
    query?: QueryPathFields;
    updateQuery?: (params: QueryPathFields) => void;
}

const EmployeeList = ({ query, updateQuery }: EmployeeListProps): JSX.Element => {
    const { data: apiEmployees, isLoading, error } = useGetEmployees(query || {});
    const history = useHistory();

    const redirectEmployeeDetail = (id: string) => {
        history.push(`/employees/${id}`);
    };

    const EMPLOYEES_COLUMN_NAMES: ColumnProps[] = [
        {
            id: 'companyShortName', displayName: 'Account shortname',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'employeeEinName', displayName: 'EIN name',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'employeeFirstName', displayName: 'First name',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'employeeLastName', displayName: 'Last name',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'openAlerts', displayName: 'Open alerts',
        },
        {
            id: 'closedAlerts', displayName: 'Closed alerts',
        }
    ];

    return (
        <section>
            {error && <p>Error: {error}</p>}
            {apiEmployees && apiEmployees.data && <TableComponent
                indexId='employeeAccountId'
                data={apiEmployees.data}
                total={apiEmployees.total || apiEmployees.data.length}
                columns={EMPLOYEES_COLUMN_NAMES}
                loading={isLoading}
                defaultPage={query ? query.page : 1}
                defaultPageSize={query ? query.pageSize : 30}
                rowClickHandler={redirectEmployeeDetail}
                updateHandler={updateQuery} />}
        </section>
    );
};

export { EmployeeList };
