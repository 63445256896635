import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from '@middleware/authorization';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from '@components/styles/theme';
import { ToastProvider } from '@components/toast/Toast';

ReactDOM.render(
    <Auth0Provider>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <ToastProvider>
                    <App />
                </ToastProvider>
            </BrowserRouter>
        </ThemeProvider>
    </Auth0Provider>, document.getElementById('root'));
