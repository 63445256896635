import React, { useState } from 'react';
import { Box } from 'rebass/styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSignOutAlt, faChartLine, faExclamationTriangle,
    faThList, faIdCardAlt, faUsers, faCog, faClock, IconDefinition, faListAlt, faAward,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@middleware/authorization';
import { SidebarWrapper, SidebarMenu, SidebarMenuItem } from './components/sidebar.styled';
import { LogoWurk } from '@shared/assets/logos';
import { IconButton } from '@components/styles/button.styled';
import { Tooltip, TooltipModeEnum, TooltipPositionEnum } from '@components/tooltip';
import { StyledLink } from '@components/styles/link.styled';

type ActiveMenu = 'monitoring' | 'configuration';

const Sidebar = ({ isOpen }: { isOpen: boolean }) => {
    const [activeMenu, setActiveMenu] = useState<ActiveMenu>('monitoring');
    const { logout, isAuthenticated } = useAuth0();

    const logOut = () => {
        logout({ returnTo: window.location.origin });
    };

    const getElement = (isOpen: boolean, to: string, content: string, icon: IconDefinition) =>
        isOpen ? <StyledLink to={to}>
            <IconButton>
                <FontAwesomeIcon color='white' size='lg' icon={icon} />
            </IconButton>
            <span>{content}</span>
        </StyledLink> : <Tooltip content={content} mode={TooltipModeEnum.HOVERED}
            position={TooltipPositionEnum.RIGHT}>
                <StyledLink to={to}>
                    <IconButton>
                        <FontAwesomeIcon color='white' size='lg' icon={icon} />
                    </IconButton>
                </StyledLink>
            </Tooltip>;

    return (
        <SidebarWrapper isOpen={isOpen}>
            <StyledLink to={'/'}>
                <img src={LogoWurk.default}
                    width={`${isOpen ? 116 : 73}px`}
                    height={`${isOpen ? 116 : 73}px`} />
            </StyledLink>
            <Box margin='10px 0' width='100%'>
                <SidebarMenu>
                    <SidebarMenuItem isMenuActive={activeMenu === 'monitoring'} isOpen={isOpen}>
                        <div onClick={() => setActiveMenu('monitoring')}>
                            {getElement(isOpen, '/alerts', 'Monitoring', faChartLine)}
                        </div>
                        {activeMenu === 'monitoring' && <ul>
                            <li>
                                {getElement(isOpen, '/alerts', 'Alerts', faExclamationTriangle)}
                            </li>
                            <li>
                                {getElement(isOpen, '/companies', 'Accounts', faThList)}
                            </li>
                            <li>
                                {getElement(isOpen, '/eins', 'EIN’s', faIdCardAlt)}
                            </li>
                            <li>
                                {getElement(isOpen, '/licenses', 'Licenses', faAward)}
                            </li>
                            <li>
                                {getElement(isOpen, '/employees', 'Employees', faUsers)}
                            </li>
                            <li>
                                {getElement(isOpen, '/whitelistrules', 'Whitelist rules', faListAlt)}
                            </li>
                        </ul>}
                    </SidebarMenuItem>
                </SidebarMenu>
                <SidebarMenu>
                    <SidebarMenuItem isMenuActive={activeMenu === 'configuration'} isOpen={isOpen}>
                        <div onClick={() => setActiveMenu('configuration')}>
                            {getElement(isOpen, '/configuration/jobs', 'Configuration', faCog)}
                        </div>
                        {activeMenu === 'configuration' && <ul>
                            <li>
                                {getElement(isOpen, '/configuration/jobs', 'Jobs', faClock)}
                            </li>
                        </ul>}
                    </SidebarMenuItem>
                </SidebarMenu>
                <SidebarMenu>
                    <SidebarMenuItem isMenuActive={false} isOpen={isOpen}>
                        {isAuthenticated && <div onClick={logOut}>{isOpen ? <>
                            <IconButton>
                                <FontAwesomeIcon color='white' size='lg' icon={faSignOutAlt} />
                            </IconButton>
                            <span color='background'>Sign out</span>
                        </> : <Tooltip content='Sign out' mode={TooltipModeEnum.HOVERED}
                            position={TooltipPositionEnum.RIGHT}>
                                <IconButton>
                                    <FontAwesomeIcon color='white' size='lg' icon={faSignOutAlt} />
                                </IconButton>
                            </Tooltip>}</div>}
                    </SidebarMenuItem>
                </SidebarMenu>
            </Box>
        </SidebarWrapper >
    );
};

export default Sidebar;
