import React, { useEffect, useState } from 'react';
import { InlineText } from '@components/styles/text.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt, faTrash, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Box, Flex, Button } from 'rebass/styled-components';
import { useHistory } from 'react-router-dom';
import { DeletedWhitelistRuleList } from '@components/whitelistRule/DeletedWhitelistRuleList';
import { getObjFromQuery, getRawQuery } from '@utils/queryParams';

const DeletedWhitelistRulesList = (): JSX.Element => {
    const history = useHistory();
    const [query, setQuery] = useState({
        ...(history.location.search ?
            getObjFromQuery(history.location.search) : {
                page: 1, pageSize: 30,
                withDeleted: String(true),
                deletedOnly: String(true),
            })
    });
    useEffect(() => {
        history.push(`${history.location.pathname}?${getRawQuery(query)}`);
    }, [query]);

    return (<section>
        <Flex mr='40px' alignItems='center'>
            <Box>
                <InlineText fontSize={27} ><FontAwesomeIcon icon={faListAlt} /></InlineText>
                <InlineText fontSize={43}> Whitelist rules | </InlineText>
                <InlineText fontSize={27} ><FontAwesomeIcon icon={faTrash} /> &nbsp; Deleted</InlineText>
            </Box>
            <Box ml='auto'>
                <Button variant='outline'
                    onClick={() => { history.goBack(); }}>
                    <FontAwesomeIcon icon={faChevronLeft} /> &nbsp; Back
                </Button>
            </Box>
        </Flex>
        <DeletedWhitelistRuleList query={query} updateQuery={setQuery} />
    </section>);
};

export default DeletedWhitelistRulesList;
