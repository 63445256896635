import React from 'react';
import { Flex, Box, Text } from 'rebass/styled-components';
import { currencyFormat } from '@utils/formatting';

interface AlertTypeTransactionDetailProps {
    alertTypeDetail: React.ReactNode[] | React.ReactNode;
    children: React.ReactNode[] | React.ReactNode;
    payStatementGross: number;
    previousGrossNet?: number;
}

const AlertTypeDetailWrapper = ({ alertTypeDetail,
    children, payStatementGross, previousGrossNet }: AlertTypeTransactionDetailProps): JSX.Element => {

    const getPercentage = (actual: number, previous: number): number => {
        return (100 * actual / previous) - 100;
    };

    return <>
        <Flex p={30} pr={16} pl={16} mb={40}>
            <Box minWidth='auto' pr={20} width={1 / 3}>
                <Text fontSize={30} fontWeight={600}>Last Payment</Text>
                <Text fontSize={68} fontWeight='bold' pb={10} pt={10}>
                    {currencyFormat(payStatementGross)}
                </Text>
                {previousGrossNet &&
                    <Text fontSize={18} fontWeight='bold' color='danger'>
                        {payStatementGross > previousGrossNet && '+'}
                        {getPercentage(payStatementGross, previousGrossNet).toFixed(2)}%
                        {' or '}
                        {currencyFormat(previousGrossNet)}
                    </Text>}
            </Box>
            <hr />
            <Box minWidth='auto' pl={20} width={1}>
                <Text fontSize={30} fontWeight={600}>Alert Description</Text>
                {alertTypeDetail}
            </Box>
        </Flex>
        {children}
    </>;
};

export { AlertTypeDetailWrapper };
