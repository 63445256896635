import React, { useState } from 'react';
import { Moment } from 'moment';
import { Button, Flex } from 'rebass/styled-components';
import theme from '@components/styles/theme';

import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
const { default: aphroditeInterface } = require('react-with-styles-interface-aphrodite');
const { default: DefaultTheme } = require('react-dates/lib/theme/DefaultTheme');

ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme({
    reactDates: {
        ...DefaultTheme.reactDates,
        border: {
            ...DefaultTheme.reactDates.border,
            input: {
                ...DefaultTheme.reactDates.border.input,
                borderBottomFocused: `2px solid ${theme.colors.primary}`,
            },
            pickerInput: {
                borderWidth: 1,
                borderStyle: 'solid',
                borderRadius: 0,
            },
        },
        sizing: {
            inputWidth: '70px',
            inputWidth_small: '70px',
            arrowWidth: '0',
        },
        font: {
            ...DefaultTheme.reactDates.font,
            input: {
                ...DefaultTheme.reactDates.font.input,
                weight: 600,
                size_small: 11,
                lineHeight_small: '21px',
                letterSpacing_small: 0,
            },
        },
        color: {
            ...DefaultTheme.reactDates.color,
            border: 'black',
            selected: {
                ...DefaultTheme.reactDates.color.selected,
                backgroundColor: theme.colors.secondary,
                backgroundColor_active: theme.colors.secondary,
                backgroundColor_hover: theme.colors.secondary,
                borderColor: theme.colors.secondary,
                borderColor_active: theme.colors.secondary,
                borderColor_hover: theme.colors.secondary,
            },
            hoveredSpan: {
                backgroundColor: theme.colors.secondaryLight,
                backgroundColor_active: theme.colors.secondaryLight,
                backgroundColor_hover: theme.colors.secondaryLight,
                borderColor: theme.colors.secondaryLight,
                borderColor_active: theme.colors.secondaryLight,
                borderColor_hover: theme.colors.secondaryLight,
                color: theme.colors.background,
                color_active: theme.colors.background,
                color_hover: theme.colors.background,
            },
            selectedSpan: {
                backgroundColor: theme.colors.secondaryLight,
                backgroundColor_active: theme.colors.secondaryLight,
                backgroundColor_hover: theme.colors.secondaryLight,
                borderColor: theme.colors.secondaryLight,
                borderColor_active: theme.colors.secondaryLight,
                borderColor_hover: theme.colors.secondaryLight,
                color: theme.colors.background,
                color_active: theme.colors.background,
                color_hover: theme.colors.background,
            },
        },
    },
});

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker as AirbnbDateRangePicker } from 'react-dates';

interface DateRangePickerProps {
    onSetRange: (startDate: string, endDate: string) => void;
}

export const DateRangePicker = ({ onSetRange }: DateRangePickerProps): JSX.Element => {

    const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);
    const [startDate, setStartDate] = useState<Moment | null>(null);
    const [endDate, setEndDate] = useState<Moment | null>(null);
    const handleClose = (startDate: Moment | null, endDate: Moment | null): void => {
        setFocusedInput(null);
        onSetRange(startDate ? startDate.toISOString() : '', endDate ? endDate.toISOString() : '');
    };

    const renderOkButton = (): JSX.Element => <Flex p='0 10px 10px 10px'>
        <Button ml='auto' onClick={() => handleClose(startDate, endDate)}>OK</Button>
    </Flex>;

    return <AirbnbDateRangePicker
        startDatePlaceholderText='From'
        endDatePlaceholderText='To'
        calendarInfoPosition='bottom' renderCalendarInfo={renderOkButton}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={({ startDate, endDate }) => {
            if (!startDate && !endDate) handleClose(startDate, endDate);
            setStartDate(startDate);
            setEndDate(endDate);
        }}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        onClose={() => handleClose(startDate, endDate)} showClearDates
        keepOpenOnDateSelect numberOfMonths={1} small hideKeyboardShortcutsPanel
        endDateId='filter-start-date' startDateId='filter-end-date' displayFormat='YYYY/MM/DD'
        isOutsideRange={() => false} />;
};
