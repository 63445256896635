import { QueryPathFields, QueryPathFieldsDefaultValues } from '@providers/apiProvider';

export const validateQueryDefinition = <T>(query: QueryPathFields, definition: T) => {
    const keys: string[] = Object.keys({
        ...QueryPathFieldsDefaultValues,
        ...definition,
    });
    Object.keys(query).forEach(key => !keys.includes(key) && delete query[key]);
};

export const getObjFromQuery = (query: string): QueryPathFields => {
    const params = (new URLSearchParams(query)).entries();
    const queryObj: QueryPathFields = {};

    for (const [key, val] of params) {
        queryObj[key] = val;
    }

    return queryObj;
};

export const getRawQuery = (params: QueryPathFields): string => {
    const searchParams = new URLSearchParams();

    Object.entries(params).map(([key, val]) =>
        val && searchParams.set(key, String(val)));

    return searchParams.toString();
};
