export interface Employee {
    employeesStatus: string;
    employeeVeteran: string;
    employeeTerminatedDate: string;
    id: string;
    employeeAccountId: string;
    employeeSeasonalWorker: string;
    employeeRehiredDate: string;
    employeeUnion: string;
    employeePrimaryLanguage: string;
    employeePayrollClassification: string;
    employeeLastName: string;
    employeeHiredDate: string;
    employeeType: string;
    employeeHasDirectDeposit: string;
    employeeCitizenship: string;
    employeeFtstudent: string;
    employeeMonthlyPayAmount: string;
    employeeInpayroll: string;
    employeeFirstName: string;
    employeePayType: string;
    companyDbId: string;
    employeeEeoClassification: string;
    employeeAddressZip: string;
    employeeJobTitle: string;
    employeeBirthday: string;
    employeeAddressState: string;
    employeeStartedDate: string;
    employeeEthnicity: string;
    employeeGender: string;
    employeeAddress2: string;
    employeeAddressCity: string;
    employeeAddress3: string;
    employeeAddress1: string;
    companyKronosId: string;
    employeeId: string;
    companyShortName: string;
    openAlerts: number;
    closedAlerts: number;
    employeeEinName: string;
}

export const EmployeeDefaultValue: Employee = {
    employeesStatus: '',
    employeeVeteran: '',
    employeeTerminatedDate: '',
    id: '',
    employeeAccountId: '',
    employeeSeasonalWorker: '',
    employeeRehiredDate: '',
    employeeUnion: '',
    employeePrimaryLanguage: '',
    employeePayrollClassification: '',
    employeeLastName: '',
    employeeHiredDate: '',
    employeeType: '',
    employeeHasDirectDeposit: '',
    employeeCitizenship: '',
    employeeFtstudent: '',
    employeeMonthlyPayAmount: '',
    employeeInpayroll: '',
    employeeFirstName: '',
    employeePayType: '',
    companyDbId: '',
    employeeEeoClassification: '',
    employeeAddressZip: '',
    employeeJobTitle: '',
    employeeBirthday: '',
    employeeAddressState: '',
    employeeStartedDate: '',
    employeeEthnicity: '',
    employeeGender: '',
    employeeAddress2: '',
    employeeAddressCity: '',
    employeeAddress3: '',
    employeeAddress1: '',
    companyKronosId: '',
    employeeId: '',
    companyShortName: '',
    openAlerts: -1,
    closedAlerts: -1,
    employeeEinName: ''
};
