import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import { Flex, Box, Text } from 'rebass/styled-components';
import { InlineHeading } from '@components/styles/text.styled';
import { Tooltip, TooltipModeEnum, TooltipPositionEnum } from '@components/tooltip';
import { CompanySummary } from '@components/company/CompanySummary';
import { StyledLink } from '@components/styles/link.styled';
import { WhitelistRule } from '@providers/types';

const WhitelistRuleDetailHeader = ({ whitelistRule }: { whitelistRule: WhitelistRule }): JSX.Element =>
    <Box mt={45}>
        <Flex justifyContent='space-between' alignItems='center' pb={15}>
            <Box>
                <InlineHeading fontSize={28} color='prmary'>
                    <FontAwesomeIcon icon={faListAlt} /> &nbsp; <b>Whitelist Rule |</b>
                </InlineHeading>
                <InlineHeading fontSize={28} fontWeight={400}> {whitelistRule.id}</InlineHeading>
            </Box>
        </Flex>
        <Flex p={30} pr={16} pl={16}>
            <Box width={1 / 2} pr={20}>
                <Text fontSize={36} fontWeight='bold' pb={10} pt={10}>
                    {whitelistRule.alertType.name}
                </Text>
                <Text fontSize={18} pt={20} minHeight={96}>
                    {whitelistRule.reason}
                </Text>
            </Box>
            <hr />
            <Box width={1 / 2} pl={20}>
                <Text fontSize={18}>
                    Company: &nbsp;
                <Tooltip content={whitelistRule.companyId && <CompanySummary companyId={whitelistRule.companyId} />}
                        mode={TooltipModeEnum.HOVERED} position={TooltipPositionEnum.BOTTOM}>
                        <Text fontSize={18} pr={20}>
                            <StyledLink to='/'>{whitelistRule.companyName}</StyledLink>
                        </Text>
                    </Tooltip>
                </Text>
                <Text fontSize={18}>EIN: &nbsp; <b>{whitelistRule.EIN}</b></Text>
                <Text fontSize={18}>Employee: &nbsp; <b>{whitelistRule.employeeId}</b></Text>
            </Box>
        </Flex>
    </Box>;

export { WhitelistRuleDetailHeader };
