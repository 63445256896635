import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSave } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '@components/modal/Modal';
import { ModalSaveButton } from '@components/modal/components/modal.styled';
import { Button } from 'rebass/styled-components';
import { useAuth0 } from '@middleware/authorization';
import { deleteWhitelistRules } from '@providers/whitelistRule';

const DeleteRules = ({ whitelistRuleIds, removeAllChecked }:
    { whitelistRuleIds: string[], removeAllChecked: (whitelistRuleIds: string[]) => void }): JSX.Element => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const { token } = useAuth0();

    const handleDelete = (): void => {
        deleteWhitelistRules(whitelistRuleIds, token);
        removeAllChecked(whitelistRuleIds);
        setShowModal(false);
    };

    return (
        <>
            <Modal visible={showModal} setVisible={setShowModal}
                header={<><FontAwesomeIcon icon={faTrash} /> Delete whitelis rules</>}
                footer={<ModalSaveButton onClick={handleDelete}>
                    <FontAwesomeIcon icon={faSave} /> &nbsp; Delete
                </ModalSaveButton>}>
                Delete ({whitelistRuleIds.length}) rules?
            </Modal>
            <Button variant='primary' mr={2} p={3} onClick={() => setShowModal(!showModal)}>
                <FontAwesomeIcon icon={faTrash} /> Delete whitelist rules
            </Button>
        </>
    );
};

export { DeleteRules };
