import React from 'react';
import TableComponent from '@components/table/Table';
import { useGetPayrolls } from '@providers/payroll';
import { Payroll, defaultUseGetPayrollsFields } from '@providers/types';
import { QueryPathFields } from '@providers/apiProvider';
import { ColumnFormatterDefaultValues, ColumnProps } from '@components/table/TableHeader';
import { TableFilterTypeEnum } from '@components/table/tableFilter';
import { HighlightColor } from '@components/table/TableContent';

interface PayrollListProps {
    query?: QueryPathFields;
    updateQuery?: (params: QueryPathFields) => void;
    highlightedPayrolls?: { [payroll: string]: HighlightColor };
}

const PayrollList = ({ query, updateQuery, highlightedPayrolls }: PayrollListProps): JSX.Element => {
    const stateQuery = query ?
        { ...defaultUseGetPayrollsFields, ...query } : { ...defaultUseGetPayrollsFields };
    const { isLoading, error, data: apiPayrolls }: {
        data?: { data: Payroll[], total: number },
        error?: string, isLoading: boolean,
    } = useGetPayrolls(stateQuery);

    const PAYROLLS_COLUMN_NAMES: ColumnProps[] = [
        {
            id: 'payrollName', displayName: 'Payroll name',
        },
        {
            id: 'payDate', displayName: 'Paid date',
            formatter: ColumnFormatterDefaultValues.dateFormatter,
        },
        {
            id: 'payStatementNet', displayName: 'Net amount',
            formatter: ColumnFormatterDefaultValues.currencyFormatter,
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'payrollCumulativeGross', displayName: 'Gross amount',
            formatter: ColumnFormatterDefaultValues.currencyFormatter,
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
    ];

    return (
        <section>
            {error && <p>Error: {error}</p>}
            {apiPayrolls && apiPayrolls.data && <TableComponent
                indexId='payrollName'
                data={apiPayrolls.data}
                total={apiPayrolls.total || apiPayrolls.data.length}
                columns={PAYROLLS_COLUMN_NAMES}
                loading={isLoading}
                highlightedRows={highlightedPayrolls}
                defaultPage={stateQuery.page}
                defaultPageSize={stateQuery.pageSize}
                defaultSort={{ sortField: stateQuery.sortField, sortDirection: stateQuery.sortDirection }}
                updateHandler={updateQuery} />}
        </section>
    );
};

export { PayrollList };
