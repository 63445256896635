import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt, faChevronLeft, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '@components/modal/Modal';
import { ModalSaveButton } from '@components/modal/components/modal.styled';
import { CommentForm } from '@components/comment/CommentForm';
import { useAuth0 } from '@middleware/authorization';
import { postComment } from '@providers/comment';
import { patchAlert } from '@providers/alert';
import { StatusEnum, Alert, Comment, WhitelistRule } from '@providers/types';
import { DisableableButton } from '@components/styles/button.styled';
import { postWhitelistRule } from '@providers/whitelistRule';
import { Flex, Heading, Text, Box } from 'rebass/styled-components';
import { useHistory } from 'react-router-dom';
import useToast from '@components/toast/hooks/useToast';

const AlertResolveWhitelist = ({ alert, setForceUpdate }: {
    alert: Alert, setForceUpdate: () => void
}): JSX.Element => {
    const history = useHistory();
    const { showWarnToast, showErrorToast } = useToast();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showSummaryModal, setShowSummaryModal] = useState<boolean>(false);
    const [createdWhitelistRule, setCreatedWhitelistRule] = useState<WhitelistRule>();
    const [stateComment, setStateComment] = useState<Comment>();
    const { user, token } = useAuth0();

    const postNewComment = async (): Promise<void> => {
        if (stateComment && stateComment.body) {
            const newComment: Comment = {
                ...stateComment,
                alert: alert.id,
                createdBy: user.sub,
            };
            const updatedAlert: Alert = {
                ...alert, status: StatusEnum.WHITELIST_RESOLVED,
            };

            try {
                const postedRule = await postWhitelistRule({
                    EIN: updatedAlert.EIN,
                    alertType: updatedAlert.alertType,
                    companyId: updatedAlert.companyId,
                    companyName: updatedAlert.companyName,
                    createdBy: user,
                    employeeId: updatedAlert.employeeId,
                    reason: newComment.body,
                }, token);

                setShowModal(false);
                if (postedRule.data) {
                    postComment(newComment, token);
                    patchAlert({ ...updatedAlert, whitelistedBy: postedRule.data.id }, token);

                    setShowSummaryModal(true);
                    setCreatedWhitelistRule(postedRule.data);
                }
                postedRule.error && showWarnToast(<>
                    <FontAwesomeIcon icon={faExclamationTriangle} />  <b>{postedRule.error}</b>
                </>);
            } catch (e) {
                showErrorToast(e);
                handleClose(false);
            }
        }
    };

    const handleClose = (visible: boolean) => {
        setShowSummaryModal(visible);
        !visible && setForceUpdate();
    };

    return (<>
        {createdWhitelistRule && <Modal visible={showSummaryModal} setVisible={handleClose}
            header={<>
                <FontAwesomeIcon icon={faListAlt} />
                Whitelist rule {createdWhitelistRule.id} created
            </>}
            footer={<>
                <ModalSaveButton onClick={() => history.goBack()}>
                    <FontAwesomeIcon icon={faChevronLeft} /> Alert list
                </ModalSaveButton>
                <ModalSaveButton onClick={() => history.push(`/whitelistrules/${createdWhitelistRule.id}`)} >
                    Whitelist rules details <FontAwesomeIcon icon={faListAlt} />
                </ModalSaveButton>
            </>}> <Flex flexDirection='column' alignItems='center' justifyContent='center'>
                <Heading>
                    Alert {alert.id} has been resolved in whitelist {createdWhitelistRule.id}
                </Heading>
                <Text>
                    {createdWhitelistRule.reason}
                </Text>
                <Box width={1}>
                    <hr />
                </Box>
            </Flex>
        </Modal >}
        <Modal visible={showModal} setVisible={setShowModal}
            header={<><FontAwesomeIcon icon={faListAlt} /> Resolve alert in WHITELIST</>}
            footer={<><ModalSaveButton onClick={postNewComment}>
                Resolve alert in WHITELIST <FontAwesomeIcon icon={faListAlt} />
            </ModalSaveButton></>}>
            <CommentForm handleCommentChange={setStateComment} />
        </Modal>
        <DisableableButton disabled={alert && alert.user ? false : true} variant='primary'
            mr={2} p={3} onClick={() => setShowModal(!showModal)}>
            Resolve alert in WHITELIST <FontAwesomeIcon icon={faListAlt} />
        </DisableableButton>
    </>);
};

export { AlertResolveWhitelist };
