import React, { Component, createContext, useContext } from 'react';
import createAuth0Client, { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';

type User = any;  // FIXME: put a proper user

interface ContextValueType {
    isAuthenticated?: boolean;
    user?: User;
    isLoading?: boolean;
    handleRedirectCallback?: () => void;
    getIdTokenClaims?: (...p: any) => any;
    loginWithRedirect?: (...p: any) => any;
    getTokenSilently?: (...p: any) => any;
    logout?: (...p: any) => any;
}

interface IState {
    auth0Client: any;
    isLoading: boolean;
    isAuthenticated: boolean;
    user?: User;
    token?: string;
}

// create the context
export const Auth0Context: any = createContext<ContextValueType | null>(null);
export const useAuth0: any = () => useContext(Auth0Context);

export class Auth0Provider extends Component<{}, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            isAuthenticated: false,
            user: null,
            auth0Client: Auth0Client,
        };
    }
    config: Auth0ClientOptions = {
        domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
        client_id: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
    };
    componentDidMount() {
        this.initializeAuth0();
    }
    // initialize the auth0 library
    initializeAuth0 = async () => {
        const auth0Client = await createAuth0Client(this.config);
        this.setState({ auth0Client });

        // check to see if they have been redirected after login
        const { user, isAuthenticated } = await
            (window.location.search.includes('code=')
                ? this.handleRedirectCallback
                : this.handleReload)();

        const token = isAuthenticated ? await this.state.auth0Client.getTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
        }) : null;
        this.setState({ user, isAuthenticated, token, isLoading: false });
    }
    handleReload = async () => {
        const auth0Client = this.state.auth0Client;
        const isAuthenticated = await auth0Client.isAuthenticated();
        const user = isAuthenticated ? await auth0Client.getUser() : null;
        return { user, isAuthenticated };
    }
    handleRedirectCallback = async () => {
        const auth0Client = this.state.auth0Client;
        await auth0Client.handleRedirectCallback();
        const user = await auth0Client.getUser();
        window.history.replaceState({}, document.title, window.location.pathname);
        return { user, isAuthenticated: true };
    }
    render() {
        const { auth0Client, isLoading, isAuthenticated, user, token } = this.state;
        const { children } = this.props;
        const configObject = {
            isLoading,
            isAuthenticated,
            user,
            token,
            loginWithRedirect: (...p: any) => auth0Client.loginWithRedirect(...p),
            getTokenSilently: (...p: any) => auth0Client.getTokenSilently(...p),
            getIdTokenClaims: (...p: any) => auth0Client.getIdTokenClaims(...p),
            logout: (...p: any) => auth0Client.logout(...p),
        };
        return <Auth0Context.Provider value={configObject}>{children}</Auth0Context.Provider>;
    }
}
