import React from 'react';
import { Text } from 'rebass/styled-components';
import { Alert, Transaction } from '@providers/types';
import { TransactionList } from '@components/transaction/TransactionList';
import { QueryPathFields } from '@providers/apiProvider';
import { AlertTypeDetailWrapper } from '../components/AlertTypeDetailWrapper';
import moment from 'moment';
import { EmployeeDetail } from '../components/EmployeeDetail';
import { currencyFormat } from '@utils/formatting';
import { useGetTransactionByKronosId } from '@providers/transaction';
import { highlightAs, HighlightColor } from '@components/table/TableContent';

interface AlertDetailProps {
    alert: Alert;
    query?: QueryPathFields;
    updateQuery?: (params: QueryPathFields) => void;
    amount: number;
}

export const AlertTypeMaxPerPayment = ({ alert, query, updateQuery, amount }: AlertDetailProps): JSX.Element => {

    const { data: apiTransaction, error }: { data?: Transaction, error?: string } =
        useGetTransactionByKronosId(alert.alertDetails.transaction);

    return <>
        {error && <p>Error: {error}</p>}
        {apiTransaction && <AlertTypeDetailWrapper payStatementGross={apiTransaction.payStatementGross}
            previousGrossNet={apiTransaction.previousGrossNet} alertTypeDetail={<>
                <Text fontSize={18} pt={20} minHeight={96}>
                    On <b>{moment(alert.createdDate).format('MMMM DD, YYYY')}</b>,
                    a payment over <b>{currencyFormat(amount)}</b> was detected.
                </Text>
                <EmployeeDetail alert={alert} />
            </>}>
            <Text fontSize={38}>Transaction History</Text>
            <TransactionList
                query={query}
                updateQuery={updateQuery}
                highlightedTransactions={highlightAs([alert.alertDetails.transaction], HighlightColor.RED)} />
        </AlertTypeDetailWrapper>}</>;
};
