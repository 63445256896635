import React from 'react';
import { IconRiskHigh, IconRiskLow, IconRiskMedium } from '@shared/assets/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { RiskLevelEnum } from '@providers/types';

export interface RiskIconProps {
    value: RiskLevelEnum | string;
    size?: string;
}

export const RiskIcon = ({ value, size }: RiskIconProps) => {
    const imgIcon = (src: string) => <img src={src} alt={value} width={size} height={size} />;
    switch (value) {
        case RiskLevelEnum.HIGH:
            return imgIcon(IconRiskHigh.default);
        case RiskLevelEnum.MEDIUM:
            return imgIcon(IconRiskMedium.default);
        case RiskLevelEnum.LOW:
            return imgIcon(IconRiskLow.default);
        default:
            return <FontAwesomeIcon title='unknown' icon={faQuestionCircle} />;
    }
};

RiskIcon.defaultProps = {
    size: '23px',
};
