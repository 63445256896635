import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Flex, Box } from 'rebass/styled-components';
import { Select } from '@rebass/forms';
import { InlineText, InlineHeading } from '@components/styles/text.styled';
import { useGetUsers } from '@providers/user';
import { patchAlert } from '@providers/alert';
import { AlertColumnFormatterDefinition } from './types/alert-table.types';
import { Tooltip, TooltipModeEnum, TooltipPositionEnum } from '@components/tooltip';
import { CompanySummary } from '@components/company/CompanySummary';
import { StyledLink } from '@components/styles/link.styled';
import { useAuth0 } from '@middleware/authorization';
import { Alert, RiskLevelEnum, User } from '@providers/types';
import { Vhr } from './components/alertDetailHeader.styled';

const AlertDetailHeader = ({ alert, setForceUpdate }: {
    alert: Alert,
    setForceUpdate: () => void
}): JSX.Element => {
    const { data: users }: { data?: User[] } = useGetUsers();
    const { token } = useAuth0();

    const partchUser = (alert: Alert, token: string, users: User[], userId: string) => {
        const user = users.find(x => x.id === userId);
        const newAlert: Alert = { ...alert, user: user || null };
        patchAlert(newAlert, token).then(() => setForceUpdate());
    };

    const partchRisk = (alert: Alert, token: string, riskLevel: RiskLevelEnum) => {
        patchAlert({ ...alert, riskLevel }, token).then(setForceUpdate);
    };

    return <Box mt={45}>
        <Flex justifyContent='space-between' alignItems='center' pb={15}>
            <Box>
                <InlineHeading fontSize={28} color='prmary'>
                    <FontAwesomeIcon icon={faExclamationTriangle} /> &nbsp; <b>Alerts |</b>
                </InlineHeading>
                <InlineHeading fontSize={28} fontWeight={400}> {alert.id}</InlineHeading>
            </Box>
            <Box>
                <InlineText fontSize={28} color='grayLight'>
                    Customer Account: &nbsp; <b>{alert.companyName}</b>
                </InlineText>
            </Box>
        </Flex>
        <Flex backgroundColor='muted' justifyContent='space-between' alignItems='center' p={20} flexWrap='wrap'>
            <Flex justifyContent='flex-start' flexDirection='column'>
                <InlineText fontSize={18}>
                    <b>Alert Type: </b> &nbsp; {alert.alertType.name}
                </InlineText>
                <InlineText fontSize={18}>
                    <b>Company:</b> &nbsp;
                    <Tooltip content={alert.companyId && <CompanySummary companyId={alert.companyId} />}
                        mode={TooltipModeEnum.HOVERED} position={TooltipPositionEnum.BOTTOM}>
                        <InlineText fontSize={18} pr={20}>
                            <StyledLink to={`/companies/${alert.companyId}`}>{alert.companyName}</StyledLink>
                        </InlineText>
                    </Tooltip>
                </InlineText>
            </Flex>
            <Vhr />
            <Flex alignItems='center'>
                <InlineText fontSize={18} pl={20} >Status:</InlineText>
                <InlineText>{AlertColumnFormatterDefinition.statusFromatter(alert.status, 18)}</InlineText>
                <InlineText fontSize={18} fontWeight='bold'>{alert.status}</InlineText>
            </Flex>
            <Vhr />
            <Flex alignItems='center'>
                <InlineText htmlFor='user' fontSize={18}>Assigned to &nbsp;</InlineText>
                {users && <Select id='user'
                    name='user'
                    defaultValue={alert.user ? alert.user.id : 'Unassigned'}
                    onChange={({ target: { value } }) => partchUser(alert, token, users, value)}>
                    <option key='Unassigned'>
                        Unassigned
                        </option>
                    {users.map(user => (
                        <option key={user.id} value={user.id}>
                            {user.name}
                        </option>
                    ))}
                </Select>}
            </Flex>
            <Vhr />
            <Flex alignItems='center'>
                <InlineText htmlFor='riskLevel' fontSize={18}>Priority &nbsp;</InlineText>
                <Select id='riskLevel'
                    name='riskLevel'
                    defaultValue={alert.riskLevel}
                    onChange={({ target: { value } }) => partchRisk(alert, token, value as RiskLevelEnum)}>
                    {Object.entries(RiskLevelEnum).map(([key, value]) => (
                        <option
                            key={key}
                            value={value}>
                            {value}
                        </option>
                    ))}
                </Select>
            </Flex>
        </Flex>
    </Box >;
};

export { AlertDetailHeader };
