import { User } from './user.types';

export interface Comment {
    id?: number;
    title: string;
    body: string;
    createdBy: User;
    alert: number;
    createdDate?: string;
    updatedDate?: string;
    new_status: string | null;
    new_riskLevel: string | null;
    new_user: User | null;
}

export const CommentDefaultValue: Comment = {
    id: undefined,
    title: '',
    body: '',
    createdBy: { name: '', id: '' },
    alert: -1,
    createdDate: undefined,
    updatedDate: undefined,
    new_status: null,
    new_riskLevel: null,
    new_user: null,
};

export interface CommentTemplate {
    id: number;
    title: string;
    body: string;
    createdDate: string;
    updatedDate: string;
}

export const CommentTemplateDefaultValue: CommentTemplate = {
    id: -1,
    title: '',
    body: '',
    createdDate: '',
    updatedDate: '',
};
