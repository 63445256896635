import React from 'react';
import { LoadingContainer } from './components/loading.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { withTheme } from 'styled-components';
import { Heading } from 'rebass/styled-components';

const Loading = (props: { [key: string]: any }): JSX.Element =>
    <LoadingContainer>
        <FontAwesomeIcon color={props.theme.colors.secondaryLight} icon={faSpinner} pulse size='5x' />
        <Heading fontSize={[3, 4, 5]}>Loading ...</Heading>
    </LoadingContainer>;

export default withTheme(Loading);
