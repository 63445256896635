import styled from 'styled-components';

export const SidebarWrapper = styled.div<{ isOpen: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: ${({ isOpen }) => isOpen ? 210 : 65}px;
    transition: 0.1s;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primary};
    padding-top: 20px;
`;

export const SidebarMenu = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0;
    list-style-type: none;
    padding-inline-start: 0px;
    background-color: ${({ theme }) => theme.colors.primary};

    border-top: 1px solid ${({ theme }) => theme.colors.background};
    border-bottom: 1px solid ${({ theme }) => theme.colors.background};
`;

export const SidebarMenuItem = styled.li<{ isMenuActive: boolean, isOpen: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > div {
        cursor: pointer;
        padding: ${({ isMenuActive }) => isMenuActive ? '20px 10px 0 10px' : '10px'};
    }

    & span {
        color: ${({ theme }) => theme.colors.background};
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
    }

    & ul {
        list-style-type: none;
        padding-inline-start: 0px;
        margin-top: 20px;
        padding: 10px 0 0 0;
        background-color: ${({ theme }) => theme.colors.darkGray};
        text-align: ${({ isOpen }) => isOpen ? 'start' : 'center'};

        & li {
            padding: 10px 0;
            padding-left: ${({ isOpen }) => isOpen ? 10 : 0}px;
            border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
        }
    }
`;
