import { QueryPathFields, QueryPathFieldsDefaultValues } from '@providers/apiProvider';

export interface Transaction {
    id: number;
    employeeId: number;
    payStatemenetNet: number;
    payStatementGross: number;
    payrollCumulativeGross: number;
    checkAmount: number;
    payDate: string;
    finalizedDate: string;
    payrollType: string;
    updatedDate: string;
    payrollName: string;
    payStatementNet: number;
    companyId: string;
    EIN: string;
    payrollStatus: string;
    kronosId: number;
    companyShortName: string;
    companyType: string;
    companyName: string;
    description?: string;
    previousGrossNet?: number;
}

export const TransactionDefaultValue: Transaction = {
    id: -1,
    employeeId: -1,
    payStatemenetNet: -1,
    payStatementGross: -1,
    payrollCumulativeGross: -1,
    checkAmount: -1,
    payDate: '',
    finalizedDate: '',
    payrollType: '',
    updatedDate: '',
    payrollName: '',
    payStatementNet: -1,
    companyId: '',
    EIN: '',
    payrollStatus: '',
    kronosId: -1,
    companyShortName: '',
    companyType: '',
    companyName: '',
    description: '',
    previousGrossNet: -1,
};

export const defaultUseGetTransactionsFields: QueryPathFields = {
    ...QueryPathFieldsDefaultValues,
    sortField: 'payDate',
    sortDirection: 'DESC',
};
