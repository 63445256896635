import { useFetchAuthedApi, AuthedApiResult, fetchAuthedApi, QueryPathFields } from './apiProvider';
import { Alert, AlertDefaultValue, AlertType, User } from './types';
import { getRawQuery, validateQueryDefinition } from '@utils/queryParams';

export interface UseGetAlertsResult {
    data: Alert[];
    total: number;
}

export const useGetAlertByCaseNumber = (caseNumber: string,
    forceUpdate: boolean = false): AuthedApiResult<Alert> => {
    return useFetchAuthedApi({ path: `alerts/${caseNumber}`, forceUpdate });
};

export const useGetAlerts = (params: QueryPathFields): AuthedApiResult<UseGetAlertsResult> => {
    validateQueryDefinition<Alert>(params, AlertDefaultValue);
    return useFetchAuthedApi({ path: `alerts?${getRawQuery(params)}` });
};

export const useGetAlertTypes = (): AuthedApiResult<AlertType[]> => {
    return useFetchAuthedApi({ path: `alert-types` });
};

export const patchAlert = async (alert: Alert, token: string): Promise<void> => {
    await fetchAuthedApi({
        token,
        method: 'PUT',
        path: `alerts/${alert.id}`,
        data: alert,
    });
};

export const escalateAlert = async (
      alert: Alert, newUser: User,
      comment: {title: string, body: string}, token: string): Promise<void> =>
  {
    await fetchAuthedApi({
        token,
        method: 'POST',
        path: `alerts/${alert.id}/escalate`,
        data: {new_user: newUser, commentTitle: comment.title, commentBody: comment.body},
    });
};
