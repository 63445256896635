import React from 'react';
import { Heading, Text, Flex, Box } from 'rebass/styled-components';
import { CommentTemplate } from '@providers/types';
import { Checkbox, Label } from '@rebass/forms';

export interface CommentTemplateListProps {
  templates: CommentTemplate[];
  selected?: CommentTemplate | null;
  onSelect: (t: CommentTemplate | null) => void;
}

const CommentTemplateList : React.FC<CommentTemplateListProps> = ({ templates, selected, onSelect }) => {
    return (
        <>
            {templates.map((template: CommentTemplate) => (
                <Box key={`commentTemplate-${template.id}`}>
                    <Flex alignItems='flex-start' justifyContent='flex-start'>
                        <Box pt='10px'>
                            <Label>
                                <Checkbox id={`comment-${template.id}`}
                                    checked={selected ? selected.id === template.id : false}
                                    onChange={({ target: { checked } }:
                                        React.ChangeEvent<HTMLInputElement>) =>
                                        onSelect(checked ? template : null)} />
                            </Label>
                        </Box>
                        <Box p='10px 10px 10px 20px' width={1}>
                            <Heading fontSize='16px'>
                                {template.title}
                            </Heading>
                            <Text lineHeight='20px'>
                                {template.body}
                            </Text>
                        </Box>
                    </Flex>
                    <hr />
                </Box>
            ))}
        </>
    );
};

export { CommentTemplateList };
