import React, { useState } from 'react';
import Navbar from './navbar/Navbar';
import Sidebar from './sidebar/Sidebar';
import { Box } from 'rebass/styled-components';

const Layout = ({ children }: { children: React.ReactElement }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

    return (<>
        <Sidebar isOpen={isSidebarOpen} />
        <Box marginLeft={`${isSidebarOpen ? 220 : 75}px`}>
            <Navbar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
            <Box marginTop='100px'>
                {children}
            </Box>
        </Box>
    </>);
};

export default Layout;
