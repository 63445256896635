import React, { useState, useEffect } from 'react';
import TableComponent from '@components/table/Table';
import { useHistory } from 'react-router-dom';
import { useGetWhitelistRules } from '@providers/whitelistRule';
import { WhitelisrRuleOptionsFooter } from './WhitelistRuleOptionsFooter';
import { WhitelistRule, AlertType } from '@providers/types';
import { QueryPathFields } from '@providers/apiProvider';
import { AlertColumnFormatterDefinition } from '@components/alert/types/alert-table.types';
import { useGetAlertTypes } from '@providers/alert';
import { ColumnProps } from '@components/table/TableHeader';
import { TableFilterTypeEnum } from '@components/table/tableFilter';
import { highlightAs, HighlightColor } from '@components/table/TableContent';

const WhitelistRuleList = ({ query, updateQuery, forceUpdate, setForceUpdate }: {
    query?: QueryPathFields, updateQuery?: (params: QueryPathFields) => void,
    forceUpdate: boolean, setForceUpdate: () => void,
}): JSX.Element => {
    const { isLoading, error, data: apiWhitelistRules }: {
        isLoading: boolean, error?: string,
        data?: { data: WhitelistRule[], total: number }
    } = useGetWhitelistRules(query || {}, forceUpdate);
    const [checkedRows, setCheckedRows] = useState<string[]>([]);
    const { data: apiAlertTypes }: { data?: AlertType[] } = useGetAlertTypes();
    const [alertTypes, setAlertTypes] = useState<{ id: string, value: string }[]>([]);
    const history = useHistory();

    const redirectWhitelistDetail = (id: string) => {
        history.push(`/whitelistrules/${id}`);
    };

    const removeAllCheckedWhitelistRules = (whitelistRuleIds: string[]): void => {
        setCheckedRows(checkedRows.filter((x: string) => !whitelistRuleIds.includes(x)));
        setForceUpdate();
    };

    const selectedRowHandler = (indexId: string, checked: boolean) => {
        const newCheckedRows = checked ? [...checkedRows, indexId] : checkedRows.filter((x: string) => x !== indexId);
        setCheckedRows(newCheckedRows);
    };

    useEffect(() => {
        if (apiAlertTypes) {
            const aTypesSet: { id: string, value: string }[] = apiAlertTypes.map((type: AlertType) => (
                { id: type.name, value: type.name }
            ));
            setAlertTypes(aTypesSet);
        }
    }, [apiAlertTypes]);

    const WHITELISTRULES_COLUMN_NAMES: ColumnProps[] = [
        {
            id: 'alertType', displayName: 'Type of alert',
            formatter: AlertColumnFormatterDefinition.alertTypeFormatter,
            filterType: { type: TableFilterTypeEnum.SELECT, entity: alertTypes },
        },
        {
            id: 'reason', displayName: 'Reason',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'companyName', displayName: 'Company',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'EIN', displayName: 'EIN',
        },
        {
            id: 'employeeId', displayName: 'Employee',
        },
    ];

    return <>
        {error && <p>Error: {error}</p>}
        {apiWhitelistRules && <TableComponent
            indexId='id'
            data={apiWhitelistRules.data}
            total={apiWhitelistRules.total || apiWhitelistRules.data.length}
            columns={WHITELISTRULES_COLUMN_NAMES}
            loading={isLoading}
            rowClickHandler={redirectWhitelistDetail}
            selectedRowHandler={selectedRowHandler}
            highlightedRows={highlightAs(checkedRows, HighlightColor.WHITE)}
            updateHandler={updateQuery} />}
        {checkedRows.length > 0 && <WhitelisrRuleOptionsFooter chekedWhitelistRules={checkedRows}
            removeAllChecked={removeAllCheckedWhitelistRules} />}
    </>;
};

export { WhitelistRuleList };
