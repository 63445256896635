import React from 'react';
import { Text } from 'rebass/styled-components';
import { Alert } from '@providers/types';
import { GenericAlertTypeDetailWrapper } from '../components/GenericAlertTypeDetailWrapper';
import moment from 'moment';

interface AlertDetailProps {
    alert: Alert;
}

export const AlertTypeInvalidLicense = ({ alert }: AlertDetailProps) => {

    const problem = alert.alertDetails.problem;

    return <GenericAlertTypeDetailWrapper
              leftAlertTypeDetail={<>
                    <Text fontSize={30} fontWeight={600}>License</Text>
                    <Text fontSize={60} fontWeight='bold'>
                        {problem === 'NOT-ACTIVE' && 'NOT ACTIVE'}
                        {problem === 'EXPIRED' && 'EXPIRED'}
                        {problem === 'NOT-VERIFIED' && 'NOT VERIFIED'}
                    </Text>
              </>}
              alertTypeDetail={<>
                {Object.keys(alert.alertDetails).length && <Text fontSize={18} pt={20} minHeight={96}>
                    The system has found that,
                    on <b>{moment(alert.createdDate).format('MMMM DD, YYYY')}</b>,
                    the license for <b>{alert.companyName}</b> is no longer valid. {' '}
                    {problem === 'NOT-ACTIVE' && <>The license is <b>NOT ACTIVE</b>.</>}
                    {problem === 'EXPIRED' && <>The license is <b>EXPIRED</b>.</>}
                    {problem === 'NOT-VERIFIED' &&
                        <>The license is <b>NOT VERIFIED</b>.
                          Please confirm that the uploaded documents for this license match the license.
                        </>}
                </Text>}
            </>}>
        </GenericAlertTypeDetailWrapper>;
};
