import React from 'react';
import { TableDataCell, TableFilterRow } from '../components/table.styled';
import { TableFilterSelect } from './TableFilterSelect';
import { TableFilterInput } from './TableFilterInput';
import { TableFilterDate } from './TableFilterDate';
import { ColumnProps } from '../TableHeader';

interface TableFilterProps {
    columns: ColumnProps[];
    filterList: (newFilter: { [key: string]: string }, type?: TableFilterTypeEnum) => void;
}

export enum TableFilterTypeEnum {
    INPUT,
    SELECT,
    DATE,
}

export const TableFilter = ({ columns, filterList }: TableFilterProps): JSX.Element => {
    const getFilter = (id: string, type?: TableFilterTypeEnum,
        entity?: { id: string, value: string }[]): JSX.Element => {
        switch (type) {
            case TableFilterTypeEnum.SELECT:
                return <TableFilterSelect columnId={id} filterList={filterList}
                    options={entity ? entity : []} />;
            case TableFilterTypeEnum.DATE:
                return <TableFilterDate columnId={id} filterList={filterList} />;
            default:
                return <TableFilterInput columnId={id} filterList={filterList} />;
        }
    };

    return <TableFilterRow>
        {columns.map((key: ColumnProps) => {
            return <TableDataCell key={`search-${key.id}`}>
                {key.filterType &&
                    getFilter(key.id,
                        key.filterType ? key.filterType.type : undefined,
                        key.filterType && key.filterType.entity ? key.filterType.entity : undefined)}
            </TableDataCell>;
        })}
    </TableFilterRow>;
};
