import React from 'react';
import { Flex } from 'rebass/styled-components';
import { RestoreRules } from './RestoreRules';

const DeletedWhitelistRuleOptionsFooter = ({ chekedWhitelistRules }:
    { chekedWhitelistRules: string[] }): JSX.Element =>
    <Flex p={2} justifyContent='flex-end'>
        <RestoreRules whitelistRuleIds={chekedWhitelistRules} />
    </Flex>;

export { DeletedWhitelistRuleOptionsFooter };
