import React from 'react';
import { useParams } from 'react-router-dom';
import { DataLoader } from '@components/dataloader';
import { JobDetailForm } from '@components/job/JobDetailForm';
import { useGetJobById, Job, patchJob } from '@providers/job';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@middleware/authorization';

const JobDetail = (): JSX.Element => {
    const { jobId }: { jobId: string } = useParams();
    const history = useHistory();
    const { token } = useAuth0();

    const data = useGetJobById(jobId);

    function onSubmit(job: Job) {
        patchJob(job, token).then(() => history.push('/configuration/jobs/'));
    }

    return (
        <DataLoader
            data={data}
            render={job => <JobDetailForm initialValue={job} onSubmit={onSubmit} />}
        />
    );
};

export default JobDetail;
