import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableHead, TableRow, TableHeadCell } from './components/table.styled';
import { faSortUp, faSortDown, faSort } from '@fortawesome/free-solid-svg-icons';
import { TableFilterTypeEnum } from './tableFilter';
import { dateFormat, currencyFormat } from '@utils/formatting';

type ColumnFormatterType = ((value: string) => string) | ((value: { [key: string]: string }) => string)
    | ((value: string) => JSX.Element) | ((value: { [key: string]: string }) => JSX.Element);

export interface ColumnFormatter {
    dateFormatter: ((value: string) => string);
    currencyFormatter: ((value: string) => string);
}

export const ColumnFormatterDefaultValues: ColumnFormatter = {
    dateFormatter: (value: string): string => dateFormat(value),
    currencyFormatter: (value: string): string => currencyFormat(value),
};

export interface ColumnProps {
    id: string;
    displayName: string;
    filterType?: { type: TableFilterTypeEnum, entity?: { id: string, value: string }[] };
    formatter?: ColumnFormatterType;
}

interface TableHeaderProps {
    columns: ColumnProps[];
    sortField: string;
    sortDirection: SortDirection;
    onSetSort: (sortField: string) => void;
}

export type SortDirection = 'ASC' | 'DESC';

export interface SortType {
    sortField: string;
    sortDirection: SortDirection;
}

export const TableHeader = ({ columns, sortField, sortDirection, onSetSort }: TableHeaderProps) => <TableHead>
    <TableRow>
        {columns.map((key: ColumnProps) => {
            return <TableHeadCell key={key.id}>
                {key.displayName && <div>
                    {key.displayName} &nbsp;&nbsp;
                    <FontAwesomeIcon
                        onClick={() => onSetSort(key.id)}
                        icon={sortField !== '' && sortField === key.id ?
                            sortDirection === 'ASC' ? faSortUp : faSortDown : faSort} />
                </div>}
            </TableHeadCell>;
        })}
    </TableRow>
</TableHead>;
