import React from 'react';
import { Select } from '@components/select/Select';

interface TableFilterSelectProps {
    columnId: string;
    options: { id: string, value: string }[];
    filterList: (newFilter: { [key: string]: string }) => void;
}

export const TableFilterSelect = ({ columnId, options, filterList }: TableFilterSelectProps): JSX.Element => {
    const applyFilter = (val: string) => {
        const option: { id: string, value: string } | undefined =
            options.find(({ value }: { id: string, value: string }) => value === val);
        filterList({ [`${columnId}`]: option ? option.id : '' });
    };

    return <Select
        options={['All', ...(options.map(({ value }: { id: string, value: string }) => value))]}
        onSelect={applyFilter} />;
};
