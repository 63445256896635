import React from 'react';
import { useAuth0 } from '@middleware/authorization';
import { Flex, Text, Box } from 'rebass/styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { StyledButton } from '@components/styles/button.styled';

const MainMenu = () => {
    const { isLoading, user, isAuthenticated } = useAuth0();

    return <>
        {!isLoading && user && isAuthenticated && (
            <>
                <StyledButton>
                    <Text color='white' fontWeight='light' >
                        <FontAwesomeIcon color='white' size='lg' icon={faUserCircle} />&nbsp; Hi, {user.name}
                    </Text>
                </StyledButton>
            </>
        )}
    </>;
};


const Navbar = ({ isSidebarOpen, setIsSidebarOpen }: {
    isSidebarOpen: boolean, setIsSidebarOpen: (value: boolean) => void
}) => {
    return (
        <header>
            <Flex color='white'
                bg='secondary'
                alignItems='center'
                maxHeight='80px'
                minHeight='80px'
                padding='20px 44px'
                sx={{
                    position: 'fixed', top: 0, right: 0, zIndex: 1,
                    left: `${isSidebarOpen ? 210 : 65}px`, transition: '0.1s',
                }} >
                <FontAwesomeIcon color='white' size='lg' onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    icon={isSidebarOpen ? faTimes : faBars} width='100%' height='100%' cursor='pointer' />
                <Box mx='auto' />
                <MainMenu />
            </Flex>
        </header>
    );
};

export default Navbar;
