export enum TypeAheadPositionEnum {
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
}

export enum TypeAheadSizeEnum {
    SMALL = 120,
    MEDIUM = 240,
    LARGE = 360,
}
