import { useFetchAuthedApi, AuthedApiResult, QueryPathFields } from './apiProvider';
import { Ein, EinDefaultValue } from './types';
import { getRawQuery, validateQueryDefinition } from '@utils/queryParams';

export const useGetEINs = (params: QueryPathFields): AuthedApiResult<{ data: Ein[], total: number }> => {
    validateQueryDefinition<Ein>(params, EinDefaultValue);
    return useFetchAuthedApi<{ data: Ein[], total: number }>({
        path: `eins?${getRawQuery(params)}`,
    });
};

export const useGetEINById = (einId: string): AuthedApiResult<Ein> => {
    return useFetchAuthedApi<Ein>({
        path: `eins/${einId}`
    });
};
