import React, { useReducer, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faDownload, faSpinner, faTimesCircle, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '@components/modal/Modal';
import { Box, Button, Flex, Text } from 'rebass/styled-components';
import { Label } from '@rebass/forms';
import { License } from '@providers/types';
import { dateFormat } from '@utils/formatting';
import { getLicenseFile, postLicenseFile, useGetLicenseById } from '@providers/license';
import { ModalSaveButton } from '@components/modal/components/modal.styled';
import { StyledLink } from '@components/styles/link.styled';
import { DropZone } from '@components/dropZone/DropZone';
import { useAuth0 } from '@middleware/authorization';
import { DisableableButton } from '@components/styles/button.styled';

interface LicenseDetailModalProps {
    licenseId: string;
    showModal: boolean;
    setShowModal: (value: boolean) => void;
}

const LicenseDetailModal = ({ licenseId, showModal, setShowModal }: LicenseDetailModalProps): JSX.Element => {
    const [forceUpdate, setForceUpdate] = useReducer((x: boolean) => !x, false);
    const { data: apiLicense, isLoading, error }: {
        data?: License, isLoading: boolean, error?: string
    } = useGetLicenseById(licenseId, forceUpdate);
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const { token } = useAuth0();

    const handleUpload = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        apiLicense && apiLicense.id && selectedFile &&
            postLicenseFile(apiLicense.id, selectedFile, token).then(() => setForceUpdate());
    };

    return (
        <>
            <Modal visible={showModal} setVisible={setShowModal}
                header={<><FontAwesomeIcon icon={faAward} /> License information</>}
                footer={<ModalSaveButton onClick={() => setShowModal(false)}>
                    <FontAwesomeIcon icon={faTimesCircle} /> Close
                </ModalSaveButton>
                }>
                {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
                {error && <Text id='error' name='error' fontSize={18}>
                    {error}
                </Text>}
                {apiLicense && <Flex
                    flexDirection='column'
                    alignContent='center'
                    alignItems='center'
                    p={3}>
                    <Flex width={0.9} mx={-2} mb={3} flexDirection='column'>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='state'><b>State</b></Label>
                            <Text id='state' name='state' fontSize={18}>
                                {apiLicense.state}
                            </Text>
                        </Box>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='type'>Type of license</Label>
                            <Text id='type' name='type' fontSize={18}>
                                <b>{apiLicense.type}</b>
                            </Text>
                        </Box>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='licenseId'>License</Label>
                            <Text id='licenseId' name='licenseId' fontSize={18}>
                                <b>{apiLicense.licenseId}</b>
                            </Text>
                        </Box>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='expirationDate'>Expiration date</Label>
                            <Text id='expirationDate' name='expirationDate' fontSize={18}>
                                <b>{dateFormat(apiLicense.expirationDate)}</b>
                            </Text>
                        </Box>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='status'>Status</Label>
                            <Text id='status' name='status' fontSize={18}>
                                <b>{apiLicense.status}</b>
                            </Text>
                        </Box>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='ein'>Ein legal name</Label>

                            <Text id='status' name='status' fontSize={18}>
                                <b>{apiLicense.einName}</b>
                            </Text>
                        </Box>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='einId'>Account ein ID</Label>
                            <Text id='einId' name='einId' fontSize={18}>
                                <b>{apiLicense.einName}</b>
                            </Text>
                        </Box>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='companyShortName'>Account short name</Label>
                            <Text id='companyShortName' name='companyShortName' fontSize={18}>
                                <b>{apiLicense.companyName}</b>
                            </Text>
                        </Box>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='file'>File</Label>
                            {apiLicense.filepath ? <Flex justifyContent='space-between' alignItems='center'>
                                <Text id='file' name='file' fontSize={18}>
                                    <b>{apiLicense.filepath}</b>
                                </Text>
                                <Button onClick={() => apiLicense.id && apiLicense.filepath &&
                                    getLicenseFile(apiLicense.id, apiLicense.filepath, token)}>
                                    Download <FontAwesomeIcon icon={faDownload} />
                                </Button></Flex>
                                : <Box id='file' name='file' p={15}>
                                    {selectedFile ? <Flex justifyContent='space-between' alignItems='center'>
                                        <b>{selectedFile.name}</b>
                                        <Box>
                                            <StyledLink to=''
                                                onClick={(e: React.MouseEvent<HTMLLinkElement, MouseEvent>) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setSelectedFile(undefined);
                                                }}>
                                                change
                                            </StyledLink>
                                            &nbsp; &nbsp;
                                            <DisableableButton disabled={isLoading} variant='primary' p={3}
                                                onClick={handleUpload}>
                                                {isLoading ? <FontAwesomeIcon icon={faSpinner} spin />
                                                    : <>Upload <FontAwesomeIcon icon={faUpload} /></>}
                                            </DisableableButton>
                                        </Box>
                                    </Flex> : <DropZone id='file' name='file' handleUploadFile={setSelectedFile} />}
                                </Box>}
                        </Box>
                    </Flex>
                </Flex>}
            </Modal>
        </>
    );
};

export { LicenseDetailModal };
