import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '@components/modal/Modal';
import { ModalSaveButton } from '@components/modal/components/modal.styled';
import { CommentForm } from '@components/comment/CommentForm';
import { useAuth0 } from '@middleware/authorization';
import { postComment } from '@providers/comment';
import { patchAlert } from '@providers/alert';
import { StatusEnum, Alert, Comment } from '@providers/types';
import { DisableableButton } from '@components/styles/button.styled';

const AlertResolve = ({ alert, setForceUpdate }:
    { alert: Alert, setForceUpdate: () => void }): JSX.Element => {

    const [showModal, setShowModal] = useState<boolean>(false);
    const [stateComment, setStateComment] = useState<Comment>();
    const { user, token } = useAuth0();

    const history = useHistory();

    const handleClose = (): void => {
        setShowModal(false);
    };

    const postNewComment = (): void => {
        if (stateComment && stateComment.body) {
            const newComment: Comment = {
                ...stateComment,
                alert: alert.id,
                createdBy: user.sub,
            };
            postComment(newComment, token);

            const updatedAlert = { ...alert, status: StatusEnum.RESOLVED };
            patchAlert(updatedAlert, token).then(() => history.push(`/alerts`)).then(() => setForceUpdate());

            handleClose();
        }
    };

    return (<>
        <Modal visible={showModal} setVisible={setShowModal}
            header={<><FontAwesomeIcon icon={faCheckCircle} /> Resolve alert</>}
            footer={<ModalSaveButton onClick={postNewComment}>
                Resolve <FontAwesomeIcon icon={faCheckCircle} />
            </ModalSaveButton>}>
            <CommentForm handleCommentChange={setStateComment} />
        </Modal>
        <DisableableButton disabled={alert && alert.user ? false : true} variant='primary'
            mr={2} p={3} onClick={() => setShowModal(!showModal)}>
            Resolve <FontAwesomeIcon icon={faCheckCircle} />
        </DisableableButton>
    </>);
};

export { AlertResolve };
