import React from 'react';
import { Text } from 'rebass/styled-components';
import { Alert } from '@providers/types';
import { TransactionList } from '@components/transaction/TransactionList';
import { QueryPathFields } from '@providers/apiProvider';
import { AlertTypeDetailWrapper } from '../components/AlertTypeDetailWrapper';
import moment from 'moment';
import { EmployeeDetail } from '../components/EmployeeDetail';
import { currencyFormat } from '@utils/formatting';
import { highlightAs, HighlightColor } from '@components/table/TableContent';
import { EmployeeLink } from '@components/employee/EmployeeLink';

interface AlertDetailProps {
    alert: Alert;
    query?: QueryPathFields;
    updateQuery?: (params: QueryPathFields) => void;
    percent: number;
    diminimum: number;
}

export const AlertTypeEmployeePaymentMoreThanMovingAverage = (
    { alert, query, updateQuery, percent, diminimum }: AlertDetailProps
) => {
    const percentOver = (alert.alertDetails.total_paid_today - alert.alertDetails.two_month_average) /
        alert.alertDetails.two_month_average * 100;

    return <AlertTypeDetailWrapper payStatementGross={alert.alertDetails.total_paid_today}
        previousGrossNet={alert.alertDetails.two_month_average} alertTypeDetail={<>
            <Text fontSize={18} pt={20} minHeight={96}>
                On <b>{moment(alert.alertDetails.pay_date).format('MMMM DD, YYYY')}</b>,
                the employee <EmployeeLink id={alert.employeeId}
                    name={alert.employeeName || alert.employeeId} /> received
                {alert.alertDetails.today_pay_statements.length} payment(s)
                for a total of {currencyFormat(alert.alertDetails.total_paid_today)},
                which is {percentOver.toFixed(2)}% more than
                the 2-month average of {currencyFormat(alert.alertDetails.two_month_average)}.
                This is over the threshold of {percent}%.
            </Text>
            <EmployeeDetail alert={alert} />
            <Text fontSize={18}> Diminimum: {currencyFormat(diminimum)} </Text>
        </>}>
        <Text fontSize={38}>Transaction History</Text>
        <TransactionList
            query={query}
            updateQuery={updateQuery}
            highlightedTransactions={{
                ...highlightAs(alert.alertDetails.today_pay_statements, HighlightColor.RED),
                ...highlightAs(alert.alertDetails.previous_pay_statements, HighlightColor.YELLOW),
            }}
        />
    </AlertTypeDetailWrapper>;
};
