import styled, { keyframes, css } from 'styled-components';
import { Flex } from 'rebass/styled-components';
import { ModalContainerProps } from '../types/modal.types';

export const ModalContainer = styled.div<ModalContainerProps>`
    display: ${({ visible }) => visible ? 'block' : 'none'};
    position: fixed;
    z-index: 10000;
    padding-top: 5vh;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: ${({ theme }) => theme.colors.overlay};
`;

const slideInRight = keyframes`
    from {
        top:-300px; 
        opacity:0;
    } 
    to {
        top:0;
        opacity:1;
    }
`;

export const ModalContent = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.colors.background};
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 784px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    border-radius: 8px;
    animation: ${slideInRight} 0.4s forwards;
    @media (max-width: 784px) {
        width: 100%;
    }
`;

const baseFontCss = css`
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
`;

export const ModalHeader = styled(Flex)`
    padding: 10px 20px;
    border-radius: 8px 0;
    background-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 14px 0 rgba(0,0,0,0.09);

    color: ${({ theme }) => theme.colors.background};
    ${baseFontCss}
`;

export const ModalBody = styled(Flex)`
    padding: 10px 20px 0 20px;
`;

export const ModalCloseButton = styled.button`
    color: ${({ theme }) => theme.colors.background};
    border: 2px solid ${({ theme }) => theme.colors.background};
    border-radius: 50%;
    background-color: transparent;
    margin: auto;
    text-align: center;
    ${baseFontCss}
`;

export const ModalSaveButton = styled.button`
    color: ${({ theme }) => theme.colors.background};
    border: 2px solid ${({ theme }) => theme.colors.primaryLight};
    border-radius: 10px;
    padding: 11px 30px;
    font-size: 20px;
    background-color: ${({ theme }) => theme.colors.primary};
    margin: auto;
    text-align: center;
    ${baseFontCss}
    &:disabled {
      opacity: 0.5;
    }
`;
