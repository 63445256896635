import { Input } from '@rebass/forms';
import React from 'react';

interface TableFilterInputProps {
    columnId: string;
    filterList: (newFilter: { [key: string]: string }) => void;
}

export const TableFilterInput = ({ columnId, filterList }: TableFilterInputProps): JSX.Element =>
    <Input placeholder='Search...' onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
        filterList({ [`${columnId}`]: value })} bg='white' />;
