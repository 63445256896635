import React from 'react';
import { Flex } from 'rebass/styled-components';
import { DeleteRules } from './DeleteRules';

const WhitelisrRuleOptionsFooter = ({ chekedWhitelistRules, removeAllChecked }:
    { chekedWhitelistRules: string[], removeAllChecked: (chekedWhitelistRules: string[]) => void }): JSX.Element =>
    <Flex p={2} justifyContent='flex-end'>
        <DeleteRules whitelistRuleIds={chekedWhitelistRules} removeAllChecked={removeAllChecked} />
    </Flex>;

export { WhitelisrRuleOptionsFooter };
