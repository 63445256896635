import styled from 'styled-components';

export const TabContainer = styled.div<{ selected: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 27.5px 32px;
    box-sizing: border-box;
    border-bottom: ${({ theme, selected }) => selected ? `4px solid ${theme.colors.primary}` : `1px solid ${theme.colors.gray}`};
    font-family: Ubuntu;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    color: ${({ theme, selected }) => selected ? theme.colors.primary : theme.colors.primaryLight};
    cursor: pointer;
`;
