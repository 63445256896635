
export default {
    colors: {
        text: '#595959',
        background: '#fff',
        primary: '#22263A',
        primaryLight: '#9978A5',
        secondary: '#72597C',
        secondaryLight: '#cfd1db',
        muted: '#F2F2F2',
        gray: '#979797',
        grayLight: '#808080',
        darkGray: 'rgba(255,255,255,0.2)',
        backgroundRow: '#f2f2f2',
        danger: '#e02020',
        dangerDark: '#660000',
        overlay: 'rgb(102,102,102, 0.7)',
        highlight: {
            red: {
                background: '#FFCCCC',
                backgroundHover: '#FFAAAA',
            },
            orange: {
                background: 'rgba(255,153,0,0.3)',
                backgroundHover: 'rgba(255,153,0,0.5)',
            },
            yellow: {
                background: '#FFFFCC',
                backgroundHover: '#FFFF77',
            },
            white: {
                background: '#EAEEF5',
                backgroundHover: 'white',
            },
        },
    },
    fonts: {
        body: 'PT Sans, sans-serif',
        title: 'Ubuntu, sans-serif',
        heading: 'Ubuntu, sans-serif',
        monospace: 'Menlo, monospace',
    },
    fontSizes: [
        12, 14, 16, 20, 24, 28, 32, 48, 64, 96
    ],
    fontWeights: {
        body: 400,
        heading: 700,
        bold: 700,
    },
    lineHeights: {
        body: 1.5,
        heading: 1.25,
    },
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    sizes: {
        avatar: 48,
    },
    radii: {
        default: 4,
        circle: 99999,
    },
    shadows: {
        card: '0 0 4px rgba(0, 0, 0, .125)',
    },
    text: {
        heading: {
            fontFamily: 'heading',
            lineHeight: 'heading',
            fontWeight: 'heading',
        },
        display: {
            fontFamily: 'heading',
            fontWeight: 'heading',
            lineHeight: 'heading',
            fontSize: [5, 6, 7],
        },
        caps: {
            textTransform: 'uppercase',
            letterSpacing: '0.1em',
        },
    },
    variants: {
        avatar: {
            width: 'avatar',
            height: 'avatar',
            borderRadius: 'circle',
        },
        card: {
            p: 2,
            bg: 'background',
            boxShadow: 'card',
        },
        link: {
            color: 'primary',
        },
        nav: {
            fontSize: 1,
            fontWeight: 'bold',
            display: 'inline-block',
            p: 2,
            color: 'inherit',
            textDecoration: 'none',
            ':hover,:focus,.active': {
                color: 'primary',
            }
        },
    },
    buttons: {
        primary: {
            fontSize: 2,
            fontWeight: 'bold',
            color: 'background',
            bg: 'primary',
            borderRadius: 'default',
        },
        outline: {
            variant: 'buttons.primary',
            color: 'primary',
            bg: 'transparent',
            boxShadow: 'inset 0 0 2px',
        },
        secondary: {
            variant: 'buttons.primary',
            color: 'background',
            bg: 'secondary',
        },
    },
    styles: {
        root: {
            fontFamily: 'body',
            fontWeight: 'body',
            lineHeight: 'body',
        },
    },
};
