import React from 'react';
import { Select } from '@rebass/forms';
import { useGetUsers } from '@providers/user';
import { User } from '@providers/types';

export type UserSelectProps = {
  defaultValue?: User;
  allowUnassigned?: false;
  onChange: (selectedUser: User) => void;
} | {
  defaultValue?: User;
  allowUnassigned: true;
  onChange: (selectedUser: User | undefined) => void;
};

const UserSelect : React.FC<UserSelectProps> = ({ defaultValue, allowUnassigned, onChange }) => {
    const { data: users } = useGetUsers();

    if(users) {
      return <Select 
          id='user'
          name='user'
          defaultValue={defaultValue ? defaultValue.id : ''}
          onChange={({ target: { value } }) => onChange(users.find(user => user.id === value) as User)}>
          <option value='' disabled={!allowUnassigned}> { allowUnassigned ? 'Unassigned' : 'Select a user' } </option>
          {users.map(user => (
              <option key={user.id} value={user.id}>
                  {user.name}
              </option>
          ))}
        </Select>;
    } else {
      return null;
    }
};

export { UserSelect };
