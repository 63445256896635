import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FlexProps } from 'rebass/styled-components';
import { DropZoneContainer } from './dropZone.styled';

interface DropZoneProps extends FlexProps {
    handleUploadFile: (acceptedFiles: File, ...args: any[]) => void;
    callBackParams?: any[];
}

export const DropZone = ({ handleUploadFile, callBackParams = [] }: DropZoneProps) => {
    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.length && handleUploadFile(acceptedFiles[0], ...callBackParams);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: `application/pdf`,
        maxFiles: 1,
        maxSize: 2 * 1024 * 1024 * 1024, // 2MB
    });

    return (
        <DropZoneContainer {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>Drop the file, now...</p> :
                    <p>Drag a file or click to browse</p>
            }
        </DropZoneContainer>
    );
};
