import React, { useEffect, useReducer, useState } from 'react';
import { InlineText } from '@components/styles/text.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import { Box, Flex } from 'rebass/styled-components';
import { useHistory } from 'react-router-dom';
import { getObjFromQuery, getRawQuery } from '@utils/queryParams';
import { LicenseList } from '@components/license/LicenseList';
import { VerifyLicenseModal } from '@components/license/VerifyLicenseModal';
import { CannabizLicense, License, LicenseDefaultValue, mapLicense } from '@providers/types';
import { PostLicenseModal } from '@components/license/PostLicenseModal';
import { LicenseDetailModal } from '@components/license/LicenseDetailModal';

const LicensesList = (): JSX.Element => {
    const history = useHistory();
    const [query, setQuery] = useState({
        ...(history.location.search ?
            getObjFromQuery(history.location.search) : {
                page: 1, pageSize: 30,
            })
    });
    useEffect(() => {
        history.push(`${history.location.pathname}?${getRawQuery(query)}`);
    }, [query]);
    const [forceUpdate, setForceUpdate] = useReducer((x: boolean) => !x, false);
    const [showVerifiModal, setShowVerifyModal] = useState<boolean>(false);
    const [showPostModal, setShowPostModal] = useState<boolean>(false);
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
    const [cannabizLicense, setCannabizLicense] = useState<License>(LicenseDefaultValue);
    const [licenseId, setLicenseId] = useState<string>('');
    const handleVerifiedLicense = (cannabizLicense: CannabizLicense): void => {
        setCannabizLicense(mapLicense(cannabizLicense));
        setShowPostModal(true);
    };

    const handleShowVerifyModal = () => {
        setShowPostModal(false);
        setShowVerifyModal(true);
    };

    const handleShowLicenseInfo = (id: string): void => {
        setLicenseId(id);
        setShowInfoModal(true);
    };

    return (<section>
        <Flex mr='40px' alignItems='center'>
            <Box>
                <InlineText fontSize={27} ><FontAwesomeIcon icon={faAward} /></InlineText>
                <InlineText fontSize={43}> Licenses</InlineText>
            </Box>
            <Box ml='auto'>
                <VerifyLicenseModal setCannabizLicense={handleVerifiedLicense}
                    showModal={showVerifiModal} setShowModal={setShowVerifyModal} />
                <PostLicenseModal cannabizLicense={cannabizLicense} setForceUpdate={setForceUpdate}
                    showModal={showPostModal} setShowModal={setShowPostModal}
                    setShowVerifyModal={handleShowVerifyModal} />
                <LicenseDetailModal licenseId={licenseId}
                    showModal={showInfoModal} setShowModal={setShowInfoModal} />
            </Box>
        </Flex>
        <LicenseList query={query} updateQuery={setQuery}
            handleShowLicenseInfo={handleShowLicenseInfo} forceUpdate={forceUpdate} />
    </section>);
};

export default LicensesList;
