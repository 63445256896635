import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faSave } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '@components/modal/Modal';
import { ModalSaveButton } from '@components/modal/components/modal.styled';
import { Button } from 'rebass/styled-components';
import { Comment, CommentDefaultValue } from '@providers/types';
import { CommentForm } from './CommentForm';
import { useAuth0 } from '@middleware/authorization';
import { postComment } from '@providers/comment';

const PostComment = ({ alertId, setForceUpdate }:
    { alertId: number, setForceUpdate: () => void }): JSX.Element => {

    const [showModal, setShowModal] = useState<boolean>(false);
    const [stateComment, setStateComment] = useState<Comment>(CommentDefaultValue);
    const { user, token } = useAuth0();

    const handleClose = (): void => {
        setShowModal(false);
    };

    const postNewComment = (): void => {
        if (stateComment && stateComment.body) {
            const newComment: Comment = {
                ...stateComment,
                alert: alertId,
                createdBy: user.sub,
            };
            postComment(newComment, token).then(() => setForceUpdate());
            handleClose();
        }
    };

    return (
        <>
            <Modal visible={showModal} setVisible={setShowModal}
                header={<><FontAwesomeIcon icon={faComment} /> Add new comment</>}
                footer={<ModalSaveButton onClick={postNewComment}>
                    <FontAwesomeIcon icon={faSave} /> &nbsp; Save comment
                </ModalSaveButton>}>
                <CommentForm handleCommentChange={setStateComment} />
            </Modal>
            <Button variant='primary' mr={2} p={3} onClick={() => setShowModal(!showModal)}>
                <FontAwesomeIcon icon={faComment} /> Add new comment
            </Button>
        </>
    );
};

export { PostComment };
