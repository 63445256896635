import React, { useState } from 'react';
import { Job } from '@providers/job';
import styled from 'styled-components';
import moment from 'moment';

const Form = styled.form`
  width: 90%;
  * {
  font-size: 1.2rem;
  }
  
  dl {
    display: grid;
    grid-template-columns: 150px auto;
    column-gap: 15px;
    row-gap: 10px;
    dt {
      grid-column-start: 1;
      grid-column-end: 1;
      justify-self: end;
    }
    dd {
      margin-left: 0;
      grid-column-start: 2;
      grid-column-end: 2;
    }
  }
  button {
    width: 100%;
    &.job-enabled {
      background-color: #7f7;
    }
    &.job-disabled {
      background-color: #f77;
    }
  }
  input, textarea {
    width: 100%;
  }
  .error {
    color: red;
  }
  pre {
    margin: 0;
  }
`;

interface JobDetailFormProps {
    initialValue: Job;
    onSubmit: (job: Job) => void;
}

export const JobDetailForm = (props: JobDetailFormProps) => {
    const [job, setJob] = useState<Job>(props.initialValue);
    const [jobData, setJobData] = useState<string>(JSON.stringify(props.initialValue.data));
    const [lastRun, setLastRun] = useState<moment.Moment>(moment(props.initialValue.lastRun));
    const jobDataError = (function (text) {
        try {
            JSON.parse(text);
            return undefined;
        } catch {
            return 'Invalid JSON';
        }
    })(jobData);

    const canSubmit = !jobDataError;

    const submit = () => {
        props.onSubmit({
            ...job,
            data: JSON.parse(jobData),
            lastRun: new Date(lastRun.format()),
        });
    };

    return <Form>
        <dl>
            <dt>ID</dt>
            <dd>{job.id}</dd>
            <dt>Description</dt>
            <dd>
                <input
                    type='text'
                    value={job.name}
                    onChange={evt => setJob({ ...job, name: evt.target.value })}
                />
            </dd>

            <dt>Enabled</dt>
            <dd>
                <button
                    type='button'
                    onClick={() => setJob({ ...job, enabled: !job.enabled })}
                    className={job.enabled ? 'job-enabled' : 'job-disabled'}
                >
                    {job.enabled ? 'ENABLED' : 'DISABLED'}
                </button>
            </dd>

            <dt>Job data</dt>
            <dd>
                <textarea
                    value={jobData}
                    onChange={evt => setJobData(evt.target.value)}
                >{jobData}</textarea>
                {jobDataError && <p className='error'>{jobDataError}</p>}
            </dd>


            <dt>Last run</dt>
            <dd>{lastRun.toString()}
                <input
                    type='datetime-local'
                    value={lastRun.format('YYYY-MM-DDTHH:mm')}
                    onChange={e => setLastRun(moment(e.target.value))}
                />
            </dd>
            <dt>Last error</dt><dd><pre>{job.lastError === null ? 'No error' : job.lastError}</pre></dd>
        </dl>
        <button type='button' onClick={submit} disabled={!canSubmit}>Submit</button>
    </Form>;
};
