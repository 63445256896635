import { useFetchAuthedApi, AuthedApiResult, QueryPathFields } from './apiProvider';
import { Employee, EmployeeDefaultValue } from './types';
import { getRawQuery, validateQueryDefinition } from '@utils/queryParams';

export const useGetEmployees = (params: QueryPathFields): AuthedApiResult<{ data: Employee[], total: number }> => {
    validateQueryDefinition<Employee>(params, EmployeeDefaultValue);
    return useFetchAuthedApi<{ data: Employee[], total: number }>({
        path: `employees?${getRawQuery(params)}`,
    });
};

export const useGetEmployeeById = (employeeId: string): AuthedApiResult<Employee> => {
    return useFetchAuthedApi<Employee>({ path: `employees/${employeeId}` });
};
