import React, { useState, useEffect, useReducer } from 'react';
import { InlineText } from '@components/styles/text.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box, Flex, Button } from 'rebass/styled-components';
import { WhitelistRuleList } from '@components/whitelistRule/WhitelistRuleList';
import { WhitelistRule } from '@providers/types';
import { useGetWhitelistRules } from '@providers/whitelistRule';
import { useHistory } from 'react-router-dom';
import { getObjFromQuery, getRawQuery } from '@utils/queryParams';

const WhitelistRulesList = (): JSX.Element => {
    const history = useHistory();
    const [query, setQuery] = useState({
        ...(history.location.search ?
            getObjFromQuery(history.location.search) : {
                page: 1, pageSize: 30,
            })
    });
    useEffect(() => {
        history.push(`${history.location.pathname}?${getRawQuery(query)}`);
    }, [query]);
    const [forceUpdate, setForceUpdate] = useReducer((x: boolean) => !x, false);

    const { data: apiDeletedWhitelistRules }: {
        data?: { data: WhitelistRule[], total: number }
    } = useGetWhitelistRules({
        page: 1, pageSize: 1,
        withDeleted: String(true),
        deletedOnly: String(true),
    }, forceUpdate);
    const [deletedCount, setDeletedCount] = useState<number>(0);


    useEffect(() => {
        apiDeletedWhitelistRules && setDeletedCount(deletedCount + apiDeletedWhitelistRules.total);
    }, [apiDeletedWhitelistRules]);

    return (<section>
        <Flex mr='40px' alignItems='center'>
            <Box>
                <InlineText fontSize={27} ><FontAwesomeIcon icon={faListAlt} /></InlineText>
                <InlineText fontSize={43}> Whitelist rules</InlineText>
            </Box>
            <Box ml='auto'>
                {deletedCount > 0 && <Button variant='outline'
                    onClick={() => { history.push(`/whitelistrules/deleted`); }}>
                    <FontAwesomeIcon icon={faTrash} /> &nbsp; Deleted ({deletedCount})
                </Button>}
            </Box>
        </Flex>
        <WhitelistRuleList query={query} updateQuery={setQuery} forceUpdate={forceUpdate}
            setForceUpdate={setForceUpdate} />
    </section>);
};

export default WhitelistRulesList;
