import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import {
    IconStatusOpen, IconStatusResolved, IconStatusWhitelistResolved,
} from '@shared/assets/icons';
import { StatusEnum } from '@providers/types';


export interface StatusIconProps {
    value: StatusEnum | string;
    size?: string;
}

export const StatusIcon = ({ value, size }: StatusIconProps) => {
    const imgIcon = (src: string) => <img src={src} alt={value} width={size} height={size} />;
    switch (value) {
        case StatusEnum.OPEN:
            return imgIcon(IconStatusOpen.default);
        case StatusEnum.RESOLVED:
            return imgIcon(IconStatusResolved.default);
        case StatusEnum.WHITELIST_RESOLVED:
            return imgIcon(IconStatusWhitelistResolved.default);
        default:
            return <FontAwesomeIcon title='unknown' icon={faQuestionCircle} />;
    }
};

StatusIcon.defaultProps = {
    size: '23px',
};
