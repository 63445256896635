import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faExclamationTriangle, faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '@components/modal/Modal';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Label } from '@rebass/forms';
import { TypeAhead } from '@components/typeAhead/TypeAhead';
import { Ein, License } from '@providers/types';
import { DisableableButton } from '@components/styles/button.styled';
import { useGetEINs } from '@providers/ein';
import { TypeAheadSizeEnum } from '@components/typeAhead/types/typeAhead.types';
import { postLicense, postLicenseFile } from '@providers/license';
import { useAuth0 } from '@middleware/authorization';
import { dateFormat } from '@utils/formatting';
import { StyledLink } from '@components/styles/link.styled';
import { DropZone } from '@components/dropZone/DropZone';
import useToast from '@components/toast/hooks/useToast';

interface PostLicenseModalProps {
    cannabizLicense: License;
    setForceUpdate: () => void;
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    setShowVerifyModal: () => void;
}

const PostLicenseModal = ({ cannabizLicense, setForceUpdate, setShowVerifyModal,
    showModal, setShowModal }: PostLicenseModalProps): JSX.Element => {
    const { showWarnToast } = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [legalName, setLegalName] = useState<string>('');
    const [selectedEin, setSelectedEin] = useState<Ein>();
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const handleSelectedEin = (value: string): void => {
        const selectedEin: Ein | undefined = apiEins && apiEins.data &&
            apiEins.data.find((ein: Ein) => ein.legalName === value);
        setSelectedEin(selectedEin);
    };
    const { data: apiEins, error: einsError }: {
        data?: { data: Ein[], total: number },
        isLoading: boolean, error?: string
    } = useGetEINs({
        page: 1, pageSize: 30, legalName,
    });
    const { token } = useAuth0();

    const handleSubmit = (event: React.FormEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsLoading(true);
        selectedEin && postLicense({
            ...cannabizLicense,
            expirationDate: dateFormat(cannabizLicense.expirationDate).toString(),
            lastVerifiedDate: dateFormat(cannabizLicense.lastVerifiedDate).toString(),
            companyName: selectedEin.companyShortName,
            einId: selectedEin.companyEINId,
            einName: selectedEin.legalName,
            filepath: undefined,
        }, token).then(({ data: license, error }: {
            data?: License, error?: string
        }) => {
            license && license.id && selectedFile &&
                postLicenseFile(license.id, selectedFile, token).then(() => setForceUpdate());
            setForceUpdate();
            setIsLoading(false);
            setShowModal(false);
            error && showWarnToast(<>
                <FontAwesomeIcon icon={faExclamationTriangle} />  <b>{error}</b>
            </>);
        });
    };

    return (
        <>
            <Modal visible={showModal} setVisible={setShowModal}
                header={<><FontAwesomeIcon icon={faAward} /> Add New License</>}>
                <Flex
                    flexDirection='column'
                    alignContent='center'
                    alignItems='center'
                    as='form'
                    onSubmit={handleSubmit}
                    p={3}>
                    <Flex width={0.9} mx={-2} mb={3} flexDirection='column'>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='state'><b>State</b></Label>
                            <Text id='state' name='state' fontSize={18}>
                                {cannabizLicense.state}
                            </Text>
                        </Box>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='type'>Type of license</Label>
                            <Text id='type' name='type' fontSize={18}>
                                <b>{cannabizLicense.type}</b>
                            </Text>
                        </Box>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='licenseId'>License</Label>
                            <Text id='licenseId' name='licenseId' fontSize={18}>
                                <b>{cannabizLicense.licenseId}</b>
                            </Text>
                        </Box>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='expirationDate'>Expiration date</Label>
                            <Text id='expirationDate' name='expirationDate' fontSize={18}>
                                <b>{dateFormat(cannabizLicense.expirationDate)}</b>
                            </Text>
                        </Box>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='status'>Status</Label>
                            <Text id='status' name='status' fontSize={18}>
                                <b>{cannabizLicense.status}</b>
                            </Text>
                        </Box>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='ein'>Ein legal name</Label>
                            <TypeAhead suggestions={legalName && apiEins && apiEins.data ?
                                apiEins.data.map((ein: Ein) => ein.legalName) : einsError ? [einsError] : []}
                                setSelected={handleSelectedEin} updateSuggestions={setLegalName}
                                size={TypeAheadSizeEnum.MEDIUM}
                                id='ein'
                                name='ein'
                                placeholder='Wurk Company'
                                required />
                        </Box>
                        {selectedEin && <>
                            <Box width={1} px={2} mb={1}>
                                <Label htmlFor='einId'>Account ein ID</Label>
                                <Text id='einId' name='einId' fontSize={18}>
                                    <b>{selectedEin.companyEINId}</b>
                                </Text>
                            </Box>
                            <Box width={1} px={2} mb={1}>
                                <Label htmlFor='companyShortName'>Account short name</Label>
                                <Text id='companyShortName' name='companyShortName' fontSize={18}>
                                    <b>{selectedEin.companyShortName}</b>
                                </Text>
                            </Box>
                            <Box width={1} px={2} mb={1}>
                                <Label htmlFor='file'>File</Label>
                                <Box p={15}>
                                    {selectedFile ? <Flex justifyContent='space-between' alignItems='center'>
                                        <b>{selectedFile.name}</b>
                                        <StyledLink to=''
                                            onClick={(e: React.MouseEvent<HTMLLinkElement, MouseEvent>) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setSelectedFile(undefined);
                                            }}>
                                            change
                                        </StyledLink>
                                    </Flex>
                                        : <DropZone id='file' name='file' handleUploadFile={setSelectedFile} />}
                                </Box>
                            </Box>
                        </>}
                    </Flex>
                    <Flex width={0.9} px={2} mt={10} justifyContent='space-between' alignItems='center'>
                        <StyledLink to=''
                            onClick={(e: React.MouseEvent<HTMLLinkElement, MouseEvent>) => {

                                e.preventDefault();
                                e.stopPropagation();
                                setShowVerifyModal();
                            }}>
                            back
                        </StyledLink>
                        <DisableableButton disabled={isLoading} variant='primary' p={3}>
                            {isLoading ? <FontAwesomeIcon icon={faSpinner} spin />
                                : <>Save <FontAwesomeIcon icon={faSave} /></>}
                        </DisableableButton>
                    </Flex>
                </Flex>
            </Modal>
        </>
    );
};

export { PostLicenseModal };
