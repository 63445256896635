import {
    useFetchAuthedApi, AuthedApiResult, QueryPathFields,
    fetchAuthedApi, AuthedPromiseApiResult, uploadFile, downloadFile,
} from './apiProvider';
import { License, LicenseDefaultValue, CannabizLicense } from './types';
import { getRawQuery, validateQueryDefinition } from '@utils/queryParams';

export const useGetLicenses = (params: QueryPathFields, forceUpdate: boolean = false)
    : AuthedApiResult<{ data: License[], total: number }> => {
    validateQueryDefinition<License>(params, LicenseDefaultValue);
    return useFetchAuthedApi<{ data: License[], total: number }>({
        path: `licenses?${getRawQuery(params)}`,
        forceUpdate,
    });
};

export const useGetLicenseById = (licenseId: string, forceUpdate: boolean = false)
    : AuthedApiResult<License> => {
    return useFetchAuthedApi<License>({
        path: `licenses/${licenseId}`,
        forceUpdate,
    });
};

export const verifyLicense = async (license: CannabizLicense, token: string):
    Promise<AuthedPromiseApiResult<CannabizLicense[]>> => {
    return await fetchAuthedApi<CannabizLicense[]>({
        token,
        path: `licenses/verify`,
        data: license,
        method: 'POST',
    });
};

export const postLicense = async (license: License, token: string): Promise<AuthedPromiseApiResult<License>> => {
    return await fetchAuthedApi<License>({
        token,
        path: `licenses`,
        data: license,
        method: 'POST',
    });
};

export const postLicenseFile = async (licenseId: number, file: File,
    token: string): Promise<void> => {
    const formData = new FormData();
    formData.append(file.name, file);
    await uploadFile({
        token,
        path: `licenses/${licenseId}/file`,
        body: formData,
    });
};

export const getLicenseFile = async (licenseId: number, filepath: string, token: string): Promise<void> => {
    const file = await downloadFile({
        token,
        path: `licenses/${licenseId}/file`,
    });
    var a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.setAttribute('download', filepath);
    a.click();
};
