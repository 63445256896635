import React, { useRef, useState } from 'react';
import { Input, InputProps } from '@rebass/forms';
import { TypeAheadContainer, TypeAheadContent, TypeAheadItem } from './typeAhead.styled';
import { TypeAheadPositionEnum, TypeAheadSizeEnum } from './types/typeAhead.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLevelDownAlt, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';

interface TypeAheadProps extends InputProps {
    position?: TypeAheadPositionEnum;
    size?: TypeAheadSizeEnum;
    setSelected: (value: string) => void;
    suggestions: string[];
    updateSuggestions: (value: string) => void;
}

export const TypeAhead = ({ position, size, setSelected,
    suggestions, updateSuggestions, ...inputProps }: TypeAheadProps) => {
    const [visible, setVisible] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [value, setValue] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);
    const node = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (event: MouseEvent | React.MouseEvent<HTMLDivElement>) => {
        const { target } = event as React.MouseEvent<HTMLDivElement>;
        if (node && node.current && node.current.contains(target as Node)) {
            return;
        }
        handleVisible(false);
    };

    const handleVisible = (visible: boolean) => {
        if (visible) {
            window.addEventListener('click', handleOutsideClick);
        } else {
            window.removeEventListener('click', handleOutsideClick);
        }
        setVisible(visible);
    };

    const handleChangeInput = (value: string): void => {
        updateSuggestions(value);
        setValue(value);
        handleVisible(true);
        setError(false);
        inputRef.current && inputRef.current.focus();
    };

    const handleSelectValue = (value: string) => {
        setValue(value);
        setSelected(value);
        updateSuggestions(value);
        handleVisible(false);
        setError(false);
    };

    return <TypeAheadContainer error={error} ref={node}>
        <Input {...inputProps} value={value} ref={inputRef} onFocus={() => handleVisible(true)}
            onBlur={({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
                !suggestions.includes(value) && setError(true)}
            onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => handleChangeInput(value)} />
        {visible && value && suggestions.length > 0 &&
            <TypeAheadContent position={position || TypeAheadPositionEnum.BOTTOM}
                size={size || TypeAheadSizeEnum.MEDIUM}>
                {suggestions.map((suggestion: string, index: number) =>
                    <TypeAheadItem key={`${suggestion}-${index}`}
                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                            e.stopPropagation();
                            handleSelectValue(suggestion);
                        }}>
                        {suggestion}
                        <FontAwesomeIcon cursor='pointer' onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                            e.stopPropagation();
                            handleChangeInput(suggestion);
                        }} icon={position && position === TypeAheadPositionEnum.TOP ? faLevelDownAlt : faLevelUpAlt} />
                    </TypeAheadItem>)}
            </TypeAheadContent>}
    </TypeAheadContainer>;
};
