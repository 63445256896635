import React from 'react';
import TableComponent from '@components/table/Table';
import { useHistory } from 'react-router-dom';
import { useGetCompanies } from '@providers/company';
import { QueryPathFields } from '@providers/apiProvider';
import { ColumnProps } from '@components/table/TableHeader';
import { TableFilterTypeEnum } from '@components/table/tableFilter';

const CompanyList = ({ query, updateQuery }: {
    query?: QueryPathFields, updateQuery?: (params: QueryPathFields) => void
}): JSX.Element => {
    const { isLoading, error, data: apiCompanies } = useGetCompanies(query || {});
    const history = useHistory();

    const redirectCompanyDetail = (id: string) => {
        history.push(`/companies/${id}`);
    };

    const COMPANIES_COLUMN_NAMES: ColumnProps[] = [
        {
            id: 'kronosId', displayName: 'Account ID',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'shortName', displayName: 'Shortname',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'numberOfEINs', displayName: 'EIN’s',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'openAlerts', displayName: 'Open alerts',
        },
        {
            id: 'closedAlerts', displayName: 'Closed alerts',
        },
    ];

    return <>
        {error && <p>Error: {error}</p>}
        {apiCompanies && apiCompanies.data && <TableComponent
            indexId='kronosId'
            data={apiCompanies.data}
            total={apiCompanies.total || apiCompanies.data.length}
            columns={COMPANIES_COLUMN_NAMES}
            loading={isLoading}
            defaultPage={query ? query.page : 1}
            defaultPageSize={query ? query.pageSize : 30}
            rowClickHandler={redirectCompanyDetail}
            updateHandler={updateQuery} />}
    </>;
};

export { CompanyList };
