import React from 'react';
import { Heading, Box } from 'rebass/styled-components';
import { Textarea } from '@rebass/forms';

export interface CommentTextareaProps {
  disabled?: boolean;
  onChange?: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const CommentTextarea : React.FC<CommentTextareaProps> = ({ disabled, onChange }) => {
    return (
        <Box pl='43px'>
            <Heading htmlFor='comment' fontSize='16px' marginTop='10px'>Custom comment</Heading>
            <Textarea id='comment' disabled={disabled}
                marginTop='10px'
                name='comment'
                backgroundColor='#EAEAEA'
                height='108px'
                onChange={onChange} />
        </Box>
    );
};

export { CommentTextarea };
