import { Comment, User } from './';
import { QueryPathFields, QueryPathFieldsDefaultValues } from '@providers/apiProvider';

export enum RiskLevelEnum {
    LOW = 'low',
    MEDIUM = 'medium',
    HIGH = 'high',
}

export enum StatusEnum {
    OPEN = 'Open',
    RESOLVED = 'Resolved',
    WHITELIST_RESOLVED = 'Whitelist_Resolved',
}

export enum CategoryEnum {
    PAY_STATEMENT = 'Pay Statement',
    PAYROLL = 'Payroll'
}

export interface Alert {
    id: number;
    status: StatusEnum;
    category: CategoryEnum;
    riskLevel: RiskLevelEnum;
    alertType: { name: string, id: number };
    companyName: string;
    employeeName: string;
    user: User | null;
    employeeId?: string;
    companyId?: string;
    EIN?: string;
    comments: Comment[];
    whitelistedBy?: number;
    alertDetails: { [key: string]: any };
    createdDate: string;
    updatedDate: string;
}

export const AlertDefaultValue: Alert = {
    id: -1,
    status: StatusEnum.OPEN,
    category: CategoryEnum.PAYROLL,
    riskLevel: RiskLevelEnum.LOW,
    alertType: { name: '', id: -1 },
    companyName: '',
    employeeName: '',
    user: null,
    employeeId: '',
    companyId: '',
    EIN: '',
    comments: [],
    whitelistedBy: -1,
    createdDate: '',
    updatedDate: '',
    alertDetails: {},
};

export const defaultUseGetAlertsFields: QueryPathFields = {
    ...QueryPathFieldsDefaultValues,
    sortField: 'createdDate',
    sortDirection: 'DESC',
    status: StatusEnum.OPEN,
};
