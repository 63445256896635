import styled, { css } from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';
import { TypeAheadPositionEnum, TypeAheadSizeEnum } from './types/typeAhead.types';

const selectDirection = {
    TOP: css`
        bottom: 100%;
        left: 0;
        margin-left: 0;
    `,
    BOTTOM: css`
        top: 100%;
        left: 0;
        margin-left: 0;
    `,
};

export const TypeAheadItem = styled(Flex)`
    width: 100%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.background};
    &:hover{
        background-color: ${({ theme }) => theme.colors.muted};
    }
`;

export const TypeAheadContent = styled(Box) <{
    position: TypeAheadPositionEnum,
    size: TypeAheadSizeEnum,
}>`
    visibility: visible;
    position: absolute;
    padding: 14px 20px;
    height: max-content;
    max-height: ${({ size }) => size}px;
    width: 100%;
    overflow-y: auto;
    z-index: 1;
    opacity: 1;
    box-shadow: 0 0 14px 0 rgba(0,0,0,0.09);
    background-color: ${({ theme }) => theme.colors.background}; 

    ${({ position }) => selectDirection[position]}
`;

export const TypeAheadContainer = styled(Box) <{ error: boolean }>`
    width: 100%;
    position: relative;
    display: inline-block;
    ${({ error }) => error && css`
        box-shadow: 0px 0px 1px 1px rgba(255,0,0,0.6);
    `}
`;
