import {
    useFetchAuthedApi, AuthedApiResult, fetchAuthedApi,
    QueryPathFields, AuthedPromiseApiResult,
} from './apiProvider';
import { WhitelistRule, WhitelistRuleDefaultValue } from './types';
import { getRawQuery, validateQueryDefinition } from '@utils/queryParams';

export const useGetWhitelistRules = (params: QueryPathFields, forceUpdate: boolean = false): AuthedApiResult<{
    data: WhitelistRule[], total: number
}> => {
    validateQueryDefinition<WhitelistRule>(params, WhitelistRuleDefaultValue);
    return useFetchAuthedApi<{ data: WhitelistRule[], total: number }>({
        path: `whitelist-rules?${getRawQuery(params)}`,
        forceUpdate,
    });
};

export const useGetWhitelistRule = (whitelistRuleId: string): AuthedApiResult<WhitelistRule> => {
    return useFetchAuthedApi<WhitelistRule>({ path: `whitelist-rules/${whitelistRuleId}` });
};

export const deleteWhitelistRules = async (whitelistRulesIds: string[], token: string): Promise<void> => {
    whitelistRulesIds.forEach(async (id: string) => {
        await fetchAuthedApi({
            token,
            path: `whitelist-rules/${id}`,
            method: 'DELETE',
        });
    });
};

export const restoreWhitelistRules = async (whitelistRulesIds: string[], token: string): Promise<void> => {
    whitelistRulesIds.forEach(async (id: string) => {
        await fetchAuthedApi({
            token,
            path: `whitelist-rules/${id}/restore`,
            method: 'PATCH',
        });
    });
};

export const postWhitelistRule = async (whitelistRule: WhitelistRule,
    token: string): Promise<AuthedPromiseApiResult<WhitelistRule>> => {
    return await fetchAuthedApi<WhitelistRule>({
        token,
        path: `whitelist-rules`,
        data: whitelistRule,
        method: 'POST',
    });
};
