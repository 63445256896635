import styled, { css } from 'styled-components';
import { Button as button } from 'rebass/styled-components';

export const TableButtonDefaultValues = {
    selected: false,
};

export interface TableButtonProps {
    selected?: boolean;
}

export const Button = styled(button) <TableButtonProps>`
    border-radius: 50% !important;
    ${({ selected }) => !!selected && css`
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.background};
`}
`;
