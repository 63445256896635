import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { Flex, Box, Text } from 'rebass/styled-components';
import { InlineHeading, InlineText } from '@components/styles/text.styled';
import { Ein } from '@providers/types';
import { StyledLink } from '@components/styles/link.styled';

const EinDetailHeader = ({ ein }: { ein: Ein }): JSX.Element =>
    <Box mt={45}>
        <Flex justifyContent='space-between' alignItems='center' pb={15}>
            <Box>
                <InlineHeading fontSize={28} color='prmary'>
                    <FontAwesomeIcon icon={faUsers} /> &nbsp; <b>EIN detail |</b>
                </InlineHeading>
                <InlineHeading fontSize={28} fontWeight={400}> {ein.id}</InlineHeading>
            </Box>
        </Flex>
        <Flex p={30} pr={16} pl={16}>
            <Box width={1 / 2} pr={20}>
                <Text fontSize={36} fontWeight='bold' pb={10} pt={10}>
                    {ein.legalName}
                </Text>
                <Text fontSize={18} pt={20} minHeight={96}>
                    <b>Account</b> <StyledLink to={{
                        pathname: `/companies/${ein.companyShortName}`,
                        state: { useShortName: true }
                    }}>{ein.legalName}</StyledLink>
                </Text>
            </Box>
            <hr />
            <Flex width={1 / 2} pl={20}>
                <Flex width={1 / 2} flexDirection='column' justifyContent='center'>
                    <InlineText fontSize={18} >
                        Tax ID:&nbsp;<b>{/* FIX ME MISSING DATA */}</b>
                    </InlineText>
                    <InlineText fontSize={18} >
                        EIN state:&nbsp;<b>{ein.state}</b>
                    </InlineText>
                    <InlineText fontSize={18} >
                        Employeess:&nbsp;<b>{/* FIX ME MISSING DATA */}</b>
                    </InlineText>
                </Flex>
                <Flex width={1 / 2} flexDirection='column' justifyContent='center'>
                    <InlineText fontSize={18} >
                        Open alerts:&nbsp;<b>{ein.openAlerts}</b>
                    </InlineText>
                    <InlineText fontSize={18} >
                        Closed alerts:&nbsp;<b>{ein.closedAlerts}</b>
                    </InlineText>
                </Flex>
            </Flex>
        </Flex>
    </Box>;

export { EinDetailHeader };
