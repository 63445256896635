import styled from 'styled-components';

export const LoadingContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    
    color: ${({ theme }) => theme.colors.background};
    background-color: ${({ theme }) => theme.colors.secondary};

    height: 100vh;
    width: 100vw;
`;
