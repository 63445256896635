import React from 'react';
import { InlineText } from '@components/styles/text.styled';
import { True, False } from '@components/icons';
import {
    Table, TableBody, TableRow, TableDataCell, TableBodyRow,
    TableHead, TableHeadCell
} from '@components/table/components/table.styled';
import { Box } from 'rebass/styled-components';
import { Job } from '@providers/job';
import { Link } from 'react-router-dom';


interface JobsListProps {
    jobs: Job[];
}

export const JobsList: React.FC<JobsListProps> = ({ jobs }) => {
    return <section>
        <Box>
            <InlineText fontSize={43}> Jobs </InlineText>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeadCell>Name</TableHeadCell>
                        <TableHeadCell>Enabled</TableHeadCell>
                        <TableHeadCell>Last run</TableHeadCell>
                        <TableHeadCell>Last error</TableHeadCell>
                        <TableHeadCell>Details</TableHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {jobs.map((job) => <TableBodyRow selected={false} key={job.id}>
                        <TableDataCell>{job.name}</TableDataCell>
                        <TableDataCell>{job.enabled ? True : False}</TableDataCell>
                        <TableDataCell>{job.lastRun.toString()}</TableDataCell>
                        <TableDataCell>{job.lastError === null ? '' : 'ERROR'}</TableDataCell>
                        <TableDataCell>
                            <Link to={`/configuration/jobs/${encodeURIComponent(job.id)}`}>Details</Link>
                        </TableDataCell>
                    </TableBodyRow>)
                    }
                </TableBody>
            </Table>
        </Box>
    </section>;
};
