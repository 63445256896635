import React from 'react';
import { TabContainer } from './components/tab.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export interface TabProps extends React.HTMLProps<HTMLDivElement> {
    selected: boolean;
    children: React.ReactNode;
    icon?: IconDefinition;
}

const Tab = ({ onClick, selected, children, icon }: TabProps): JSX.Element =>
    <TabContainer onClick={onClick} selected={selected}>
        {icon && <><FontAwesomeIcon icon={icon} />&nbsp;</>}{children}
    </TabContainer>;

export { Tab };
