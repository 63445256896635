import React, { useState, useEffect } from 'react';
import TableComponent from '@components/table/Table';
import { useGetTransactions } from '@providers/transaction';
import { Transaction, PayrollTypesEnum, defaultUseGetTransactionsFields } from '@providers/types';
import { currencyFormat } from '@utils/formatting';
import { QueryPathFields } from '@providers/apiProvider';
import { HighlightColor } from '@components/table/TableContent';
import { ColumnFormatterDefaultValues, ColumnProps } from '@components/table/TableHeader';
import { TableFilterTypeEnum } from '@components/table/tableFilter';

interface TransactionListProps {
    query?: QueryPathFields;
    updateQuery?: (params: QueryPathFields) => void;
    highlightedTransactions?: { [id: string]: HighlightColor };
}

const TransactionList = ({ query, updateQuery, highlightedTransactions }: TransactionListProps): JSX.Element => {
    const stateQuery = query ?
        { ...defaultUseGetTransactionsFields, ...query } : { ...defaultUseGetTransactionsFields };
    const [tableData, setTableData] = useState<Transaction[]>([]);
    const { isLoading, error, data: apiTransactions }: {
        data?: { data: Transaction[], total: number },
        error?: string, isLoading: boolean,
    } = useGetTransactions(stateQuery);

    const getTransactionListDescription = (trans: Transaction): Transaction => {
        return {
            ...trans,
            description: [
                trans.payrollName,
                `Gross: ${currencyFormat(trans.payStatementGross)}`,
                `Net: ${currencyFormat(trans.payStatementNet)}`,
            ].join(' / ')
        };
    };

    useEffect(() => {
        const mapData: Transaction[] = (apiTransactions && apiTransactions.data.map &&
            apiTransactions.data.map((transaction: Transaction) => getTransactionListDescription(transaction))) || [];
        setTableData(mapData);
    }, [apiTransactions]);


    const TRANSACTIONS_COLUMN_NAMES: ColumnProps[] = [
        {
            id: 'payDate', displayName: 'Transaction date',
            formatter: ColumnFormatterDefaultValues.dateFormatter,
        },
        {
            id: 'description', displayName: 'Transaction description',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'payStatementGross', displayName: 'Amount',
            formatter: ColumnFormatterDefaultValues.currencyFormatter,
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'payrollType', displayName: 'Payment type',
            filterType: {
                type: TableFilterTypeEnum.SELECT,
                entity: Object.values(PayrollTypesEnum).map((e: string) => ({ id: e, value: e })),
            },
        },
    ];

    return (
        <section>
            {error && <p>Error: {error}</p>}
            {tableData && <TableComponent
                indexId='kronosId'
                data={tableData}
                total={apiTransactions ? apiTransactions.total : tableData.length}
                columns={TRANSACTIONS_COLUMN_NAMES}
                loading={isLoading}
                highlightedRows={highlightedTransactions}
                defaultPage={stateQuery.page}
                defaultPageSize={stateQuery.pageSize}
                defaultSort={{ sortField: stateQuery.sortField, sortDirection: stateQuery.sortDirection }}
                updateHandler={updateQuery} />}
        </section>
    );
};

export { TransactionList };
