import React from 'react';
import { Text } from 'rebass/styled-components';
import { Alert, Transaction } from '@providers/types';
import { TransactionList } from '@components/transaction/TransactionList';
import { QueryPathFields } from '@providers/apiProvider';
import { AlertTypeDetailWrapper } from '../components/AlertTypeDetailWrapper';
import moment from 'moment';
import { EmployeeDetail } from '../components/EmployeeDetail';
import { useGetTransactionByKronosId } from '@providers/transaction';
import { highlightAs, HighlightColor } from '@components/table/TableContent';
import { EmployeeLink } from '@components/employee/EmployeeLink';


interface AlertDetailProps {
    alert: Alert;
    max_payments: number;
    query?: QueryPathFields;
    updateQuery?: (params: QueryPathFields) => void;
}

export const AlertTypeEmployeeReceivedTooManyPayments = ({ alert, max_payments,
    query, updateQuery }: AlertDetailProps) => {

    const { data: apiTransaction, error }: { data?: Transaction, error?: string } =
        useGetTransactionByKronosId(alert.alertDetails.last_transaction);

    return <>
        {error && <p>Error: {error}</p>}
        {apiTransaction && <AlertTypeDetailWrapper payStatementGross={apiTransaction.payStatementGross}
            previousGrossNet={apiTransaction.previousGrossNet} alertTypeDetail={<>
                {Object.keys(alert.alertDetails).length && <Text fontSize={18} pt={20} minHeight={96}>
                    On <b>{moment(alert.alertDetails.pay_date).format('MMMM DD, YYYY')}</b>,
                    the employee <EmployeeLink id={alert.employeeId}
                        name={alert.employeeName || alert.employeeId} /> received
                    the {alert.alertDetails.previous_transactions.length + 1}th payment in a month,
                    thus breaching the limit of no more than {max_payments} payments in a month.
                </Text>}
                <EmployeeDetail alert={alert} />
            </>}>
            <Text fontSize={38}>Transaction History</Text>
            <TransactionList
                query={query}
                updateQuery={updateQuery}
                highlightedTransactions={
                    highlightAs([
                        ...alert.alertDetails.previous_transactions,
                        alert.alertDetails.last_transaction],
                        HighlightColor.RED)
                }
            />
        </AlertTypeDetailWrapper>}</>;
};
