import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Flex } from 'rebass/styled-components';
import { Company } from '@providers/types';
import { CompanyDetailHeader } from '@components/company/CompanyDetailHeader';
import { useGetCompanies } from '@providers/company';
import { Tab } from '@components/tab/Tab';
import { faIdCardAlt, faUsers, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InlineHeading } from '@components/styles/text.styled';
import { EinList } from '@components/ein/EinList';
import { AlertList } from '@components/alert/AlertList';
import { EmployeeList } from '@components/employee/EmployeeList';
import { QueryPathFields } from '@providers/apiProvider';
import { getObjFromQuery, getRawQuery } from '@utils/queryParams';

const CompanyDetail = (): JSX.Element => {
    enum TabsEnum {
        EINS = '#EINS',
        EMPLOYEES = '#EMPLOYEES',
        ALERTS = '#ALERTS'
    }
    const history = useHistory();

    const { useShortName }: { useShortName?: boolean } = history.location && history.location.state ?
        history.location.state : { useShortName: false };
    const initialHash: TabsEnum | undefined = Object.values(TabsEnum).find((val) => val === history.location.hash);

    const [query, setQuery] = useState({
        ...(history.location.search ?
            getObjFromQuery(history.location.search) : {
                page: 1, pageSize: 30,
            })
    });
    useEffect(() => {
        history.push(`${history.location.pathname}?${getRawQuery(query)}${history.location.hash}`);
    }, [query]);

    const { companyParam }: { companyParam: string } = useParams();
    const params: QueryPathFields = {
        page: 1, pageSize: 1,
        ...(useShortName ? { shortName: companyParam } : { kronosId: companyParam }),
    };
    const [selectedTab, setSelectedTab] = useState<TabsEnum>(initialHash || TabsEnum.EINS);
    const { data: company, isLoading, error } = useGetCompanies(params);
    const apiCompany = (company && company.data && company.data.length > 0) ? company.data[0] : undefined;

    const handleSelectTab = (selectedTab: TabsEnum): void => {
        history.push(`${history.location.pathname}${selectedTab}`);
        setSelectedTab(selectedTab);
    };

    const getTableData = (selectedTab: TabsEnum, company: Company): JSX.Element => {
        switch (selectedTab) {
            case TabsEnum.EINS:
                return <EinList query={{
                    ...query,
                    companyShortName: company.shortName,
                }}
                    updateQuery={setQuery} />;
            case TabsEnum.EMPLOYEES:
                return <EmployeeList query={{
                    ...query,
                    companyShortName: company.shortName,
                }}
                    updateQuery={setQuery} />;
            case TabsEnum.ALERTS:
                return <AlertList query={{
                    ...query,
                    companyId: company.id,
                }} updateQuery={setQuery} />;
            default:
                return <></>;
        }
    };

    const getSectionTittle = (selectedTab: TabsEnum): JSX.Element => {
        switch (selectedTab) {
            case TabsEnum.ALERTS:
                return <><FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;Alerts</>;
            case TabsEnum.EINS:
                return <><FontAwesomeIcon icon={faIdCardAlt} />&nbsp;EIN’s</>;
            case TabsEnum.EMPLOYEES:
                return <><FontAwesomeIcon icon={faUsers} />&nbsp;Employees</>;
            default:
                return <></>;
        }
    };

    return (
        <Box sx={{
            mx: 'auto',
            px: 3,
        }}>
            {isLoading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {apiCompany && <CompanyDetailHeader company={apiCompany} />}
            <hr />
            <Flex alignItems='center' justifyContent='center'>
                <Tab selected={selectedTab === TabsEnum.EINS} icon={faIdCardAlt}
                    onClick={() => handleSelectTab(TabsEnum.EINS)}>
                    EIN’s
                </Tab>
                <Tab selected={selectedTab === TabsEnum.EMPLOYEES} icon={faUsers}
                    onClick={() => handleSelectTab(TabsEnum.EMPLOYEES)}>
                    Employees
                </Tab>
                <Tab selected={selectedTab === TabsEnum.ALERTS} icon={faExclamationTriangle}
                    onClick={() => handleSelectTab(TabsEnum.ALERTS)}>
                    Alerts
                </Tab>
            </Flex>
            <Box py={20}>
                <InlineHeading fontSize={28} color='prmary'>
                    {getSectionTittle(selectedTab)}
                </InlineHeading>
            </Box>
            {apiCompany && getTableData(selectedTab, apiCompany)}
        </Box>
    );
};

export default CompanyDetail;
