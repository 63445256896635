import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from 'rebass/styled-components';
import { WhitelistRuleDetailHeader } from '@components/whitelistRule/WhitelistRuleDetailHeader';
import { useGetWhitelistRule } from '@providers/whitelistRule';
import { WhitelistRule } from '@providers/types';
import { AlertList } from '@components/alert/AlertList';

const WhitelistRuleDetail = (): JSX.Element => {
    const { whitelistRuleId }: { whitelistRuleId: string } = useParams();
    const { data: apiWhitelistRule }: { data?: WhitelistRule } = useGetWhitelistRule(whitelistRuleId);

    return (
        <Box sx={{
            mx: 'auto',
            px: 3,
        }}>
            {apiWhitelistRule && <WhitelistRuleDetailHeader whitelistRule={apiWhitelistRule} />}
            <hr />
            <AlertList query={{ whitelistedBy: whitelistRuleId }} />
        </Box>
    );
};

export default WhitelistRuleDetail;
