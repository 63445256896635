import React from 'react';
import { Box } from 'rebass/styled-components';
import { DateRangePicker } from '@components/datePicker/DateRangePicker';

interface TableFilterDateProps {
    columnId: string;
    filterList: (newFilter: { [key: string]: string }) => void;
}

export const TableFilterDate = ({ columnId, filterList }: TableFilterDateProps): JSX.Element => {
    const setRange = (columnId: string, startDate: string, endDate: string): void => {

        const dateFilter: {} = {
            dateField: !startDate && !endDate ? '' : columnId,
            from: startDate,
            to: endDate,
        };

        filterList(dateFilter);
    };

    return (<Box minWidth='180px'>
        <DateRangePicker onSetRange={(sDate: string, eDate: string) => setRange(columnId, sDate, eDate)} />
    </Box>);
};
