import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Main from './views/Main';
import GlobalStyle from '@components/styles/global.styled';
import AlertDetail from '@views/AlertDetail';
import AlertsList from '@views/AlertsList';
import JobDetail from '@views/JobDetail';
import JobsList from '@views/JobsList';
import Profile from '@components/user/Profile';
import { useAuth0 } from '@middleware/authorization';
import Layout from '@components/layout/Layout';
import Loading from '@components/loading/Loading';
import WhitelistRuleDetail from '@views/WhitelistRuleDetail';
import WhitelistRulesList from '@views/WhitelistRulesList';
import DeletedWhitelistRulesList from '@views/DeletedWhitelistRulesList';
import CompaniesList from '@views/CompaniesList';
import CompanyDetail from '@views/CompanyDetail';
import EmployeesList from '@views/EmployeesList';
import EmployeeDetail from '@views/EmployeeDetail';
import EinsList from '@views/EinsList';
import EinDetail from '@views/EinDetail';
import LicensesList from '@views/LicensesList';

const App = () => {
    const { isLoading, loginWithRedirect, isAuthenticated } = useAuth0();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    }, [isLoading, isAuthenticated]);

    return (
        <>
            <GlobalStyle />
            {isLoading ? <Loading />
                : isAuthenticated ?
                    <Layout>
                        <Switch>
                            <Route exact path='/' component={Main} />
                            <Route exact path='/profile' component={Profile} />
                            <Route exact path='/alerts/:caseNumber' component={AlertDetail} />
                            <Route exact path='/alerts' component={AlertsList} />
                            <Route exact path='/configuration/jobs' component={JobsList} />
                            <Route exact path='/configuration/jobs/:jobId' component={JobDetail} />
                            <Route exact path='/whitelistrules/' component={WhitelistRulesList} />
                            <Route exact path='/whitelistrules/deleted' component={DeletedWhitelistRulesList} />
                            <Route exact path='/whitelistrules/:whitelistRuleId' component={WhitelistRuleDetail} />
                            <Route exact path='/companies/' component={CompaniesList} />
                            <Route exact path='/companies/:companyParam' component={CompanyDetail} />
                            <Route exact path='/employees/' component={EmployeesList} />
                            <Route exact path='/employees/:employeeId' component={EmployeeDetail} />
                            <Route exact path='/eins/' component={EinsList} />
                            <Route exact path='/eins/:einId' component={EinDetail} />
                            <Route exact path='/licenses/' component={LicensesList} />
                        </Switch>
                    </Layout>
                    : <Switch>
                        <Route exact path='/' component={Loading} />
                    </Switch>}
        </>
    );
};

export default App;
