import { useFetchAuthedApi, AuthedApiResult, QueryPathFields } from './apiProvider';
import { Transaction, TransactionDefaultValue } from './types';
import { getRawQuery, validateQueryDefinition } from '@utils/queryParams';

export const useGetTransactionByKronosId = (kronosId: string): AuthedApiResult<Transaction> => {
    return useFetchAuthedApi<Transaction>({ path: `transactions/kronos/${kronosId}` });
};

export const useGetTransactions = (params: QueryPathFields)
    : AuthedApiResult<{ data: Transaction[], total: number }> => {
    validateQueryDefinition<Transaction & { alertId: number }>(params, { ...TransactionDefaultValue, alertId: -1 });
    return useFetchAuthedApi<{ data: Transaction[], total: number }>({
        path: `transactions?${getRawQuery(params)}`,
    });
};
