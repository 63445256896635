import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from 'rebass/styled-components';
import { EmployeeDetailHeader } from '@components/employee/EmployeeDetailHeader';
import { useGetEmployeeById } from '@providers/employee';
import { Employee } from '@providers/types';
import { TransactionList } from '@components/transaction/TransactionList';

const EmployeeDetail = (): JSX.Element => {
    const { employeeId }: { employeeId: string } = useParams();
    const { data: apiEmployee, isLoading, error }: {
        data?: Employee,
        isLoading?: boolean, error?: string
    } = useGetEmployeeById(employeeId);

    return (
        <Box sx={{
            mx: 'auto',
            px: 3,
        }}>
            {isLoading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {apiEmployee && <EmployeeDetailHeader employee={apiEmployee} />}
            <hr />
            {apiEmployee && <TransactionList query={{ employeeId: apiEmployee.employeeAccountId }} />}
        </Box>
    );
};

export default EmployeeDetail;
