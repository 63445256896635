import React from 'react';
import TableComponent from '@components/table/Table';
import { QueryPathFields } from '@providers/apiProvider';
import { useGetEINs } from '@providers/ein';
import { useHistory } from 'react-router-dom';
import { ColumnProps } from '@components/table/TableHeader';
import { TableFilterTypeEnum } from '@components/table/tableFilter';

interface EinListProps {
    query?: QueryPathFields;
    updateQuery?: (params: QueryPathFields) => void;
}

const EinList = ({ query, updateQuery }: EinListProps): JSX.Element => {
    const { data: apiEINs, isLoading, error } = useGetEINs(query || {});
    const history = useHistory();

    const redirectEinDetail = (id: string) => {
        history.push(`/eins/${id}`);
    };

    const EINS_COLUMN_NAMES: ColumnProps[] = [
        {
            id: 'kronosId', displayName: 'EIN ID',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'legalName', displayName: 'EIN name',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'companyShortName', displayName: 'Shortname',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'openAlerts', displayName: 'Open alerts',
        },
        {
            id: 'closedAlerts', displayName: 'Closed alerts',
        },
    ];

    return (
        <section>
            {error && <p>Error: {error}</p>}
            {apiEINs && apiEINs.data && <TableComponent
                indexId='kronosId'
                data={apiEINs.data}
                total={apiEINs.total || apiEINs.data.length}
                columns={EINS_COLUMN_NAMES}
                loading={isLoading}
                defaultPage={query ? query.page : 1}
                defaultPageSize={query ? query.pageSize : 30}
                updateHandler={updateQuery}
                rowClickHandler={redirectEinDetail} />}
        </section>
    );
};

export { EinList };
