import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from './components/tableButton.styled';
import { Text, Flex } from 'rebass/styled-components';

const range = (start: number, end: number): number[] => {
    if (start < end) return [start, ...range(start + 1, end)];
    return [start];
};

interface TablePaginationProps {
    page: number;
    dataSize: number;
    pageSize: number;
    onSetPage: (newPage: number) => void;
}

export const TablePagination = ({ page, dataSize, pageSize, onSetPage }: TablePaginationProps) => {
    const max = Math.ceil(dataSize / pageSize);
    const mid = Math.min(5, Math.floor(max / 2));
    const start = page > max ? max : page;
    const from = start - 1;
    const overflow = max - (start + mid);
    const limit = overflow < 0 ? (from + mid) + overflow : from + mid;
    const showPages = range(from, limit);
    return (<Flex alignItems='center'>
        <Text mr={2}
            fontSize={[2, 3]}>
            Page {page} of {Math.ceil(dataSize / pageSize)}
        </Text>
        {page > 1 &&
            <Button
                variant='outline'
                onClick={() => onSetPage(page - 1)}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
        }
        {showPages.map((n: number) => {
            const currentPage = n + 1;
            return (<Button
                key={`pageIndex-${currentPage}`}
                variant='outline'
                selected={page === currentPage}
                onClick={() => onSetPage(currentPage)}>
                {currentPage}
            </Button>);
        })}
        {page < max && <>
            &nbsp; ... &nbsp;
            <Button
                variant='outline'
                onClick={() => onSetPage(page + 1)}>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>
        </>}
    </Flex>);
};
