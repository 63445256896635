import React from 'react';
import { Text } from 'rebass/styled-components';
import { Alert } from '@providers/types';
import { EmployeeLink } from '@components/employee/EmployeeLink';

interface AlertDetailProps {
    alert: Alert;
}

export const EmployeeDetail = ({ alert }: AlertDetailProps) => {
    return <Text fontSize={18}>
        Employee: <EmployeeLink id={alert.employeeId}
            name={alert.employeeName || alert.employeeId} /> - <b>{alert.companyName}</b>
    </Text>;
};
