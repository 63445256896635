import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faCheckCircle, faExclamationTriangle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '@components/modal/Modal';
import { Box, Button, Flex } from 'rebass/styled-components';
import { useAuth0 } from '@middleware/authorization';
import { Input, Label } from '@rebass/forms';
import { TypeAhead } from '@components/typeAhead/TypeAhead';
import { verifyLicense } from '@providers/license';
import { CannabizLicense, LicenseStatusEnum } from '@providers/types';
import { DisableableButton } from '@components/styles/button.styled';
import useToast from '@components/toast/hooks/useToast';
import usStates from '@shared/constants/usStates';
import { TypeAheadSizeEnum } from '@components/typeAhead/types/typeAhead.types';

interface VerifyLicenseModalProps {
    setCannabizLicense: (value: CannabizLicense) => void;
    showModal: boolean;
    setShowModal: (value: boolean) => void;
}

const VerifyLicenseModal = ({ setCannabizLicense, showModal, setShowModal }: VerifyLicenseModalProps): JSX.Element => {
    const { showWarnToast } = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<string[]>(Object.values(usStates));
    const [licenseId, setLicenseId] = useState<string>('');
    const [selectedState, setSelectedState] = useState<string>('');

    const handleSelectedState = (value: string): void => {
        const selectedState: string | undefined =
            Object.keys(usStates).find((key: string) => usStates[key] === value);
        setSelectedState(selectedState || '');
    };

    const handleUpdateSuggestions = (value: string) =>
        setSuggestions(Object.values(usStates).filter((val: string) =>
            val.toLowerCase().includes(value.toLowerCase())));
    const { token } = useAuth0();

    const handleSubmit = async (event: React.FormEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsLoading(true);
        const { data: licenseVerification, error }:
            { data?: CannabizLicense[], error?: string } = await verifyLicense({
                license_id: licenseId,
                state: selectedState,
                country: 'USA',
            }, token);
        setIsLoading(false);
        setShowModal(false);
        if (licenseVerification && licenseVerification.length > 0) {
            const target = licenseVerification.find(x => x.license_id === licenseId);
            if (target) {
                if (target.status === LicenseStatusEnum.NOT_FOUND) {
                    showWarnToast(<>
                        <FontAwesomeIcon icon={faExclamationTriangle} />  <b>No valid license found</b>
                    </>);
                    return;
                }
                setCannabizLicense(target);
            }

        }
        error && showWarnToast(<>
            <FontAwesomeIcon icon={faExclamationTriangle} />  <b>{error}</b>
        </>);
    };

    return (
        <>
            <Modal visible={showModal} setVisible={setShowModal}
                header={<><FontAwesomeIcon icon={faAward} /> Add New License</>}>
                <Flex
                    flexDirection='column'
                    alignContent='center'
                    alignItems='center'
                    as='form'
                    onSubmit={handleSubmit}
                    p={3}>
                    <Flex width={0.9} mx={-2} mb={3} flexDirection='column'>
                        <Box width={1} px={2} mb={1}>
                            <Label htmlFor='state'>State</Label>
                            <TypeAhead suggestions={suggestions} size={TypeAheadSizeEnum.SMALL}
                                setSelected={handleSelectedState} updateSuggestions={handleUpdateSuggestions}
                                id='state'
                                name='state'
                                placeholder='CA'
                                required />
                        </Box>
                        <Box width={1} px={2}>
                            <Label htmlFor='licenseId'>License id</Label>
                            <Input
                                id='licenseId'
                                name='licenseId'
                                placeholder='40C-00000-0000'
                                required
                                value={licenseId}
                                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
                                    setLicenseId(value)}
                            />
                        </Box>
                    </Flex>
                    <Flex mx={-2}>
                        <Box px={2} mt={10}>
                            <DisableableButton disabled={isLoading} variant='primary' p={3}>
                                {isLoading ? <FontAwesomeIcon icon={faSpinner} spin />
                                    : <>Verify <FontAwesomeIcon icon={faCheckCircle} /></>}
                            </DisableableButton>
                        </Box>
                    </Flex>
                </Flex>
            </Modal>
            <Button variant='primary' mr={2} p={3} onClick={() => setShowModal(!showModal)}>
                Add New License <FontAwesomeIcon icon={faAward} />
            </Button>
        </>
    );
};

export { VerifyLicenseModal };
