import React, { createContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { ToastContainer } from './components/toast.styled';
import { withTheme } from 'styled-components';

const DELAY: number = 5000;

interface ToastProps {
    content: ReactNode;
    backgroundColor: string;
    color: string;
}

const ToastContext = createContext<{
    showErrorToast: (toast: ReactNode) => void;
    showInfoToast: (toast: ReactNode) => void;
    showWarnToast: (toast: ReactNode) => void;
}>({
    showErrorToast: () => { },
    showInfoToast: () => { },
    showWarnToast: () => { },
});

export default ToastContext;

const Toast = (props: { [key: string]: any }) => {
    const [toasts, setToasts] = useState<ToastProps[]>([]);

    const showErrorToast = useCallback(
        function (toast: ReactNode) {
            setToasts(toasts => [...toasts,
            {
                content: toast,
                backgroundColor: props.theme.colors.danger,
                color: props.theme.colors.background,
            }]);
        },
        [setToasts]
    );

    const showInfoToast = useCallback(
        function (toast: ReactNode) {
            setToasts(toasts => [...toasts,
            {
                content: toast,
                backgroundColor: props.theme.colors.secondaryLight,
                color: props.theme.colors.primary,
            }]);
        },
        [setToasts]
    );

    const showWarnToast = useCallback(
        function (toast: ReactNode) {
            setToasts(toasts => [...toasts,
            {
                content: toast,
                backgroundColor: props.theme.colors.dangerDark,
                color: props.theme.colors.background,
            }]);
        },
        [setToasts]
    );

    useEffect(() => {
        if (toasts.length > 0) {
            const timer = setTimeout(
                () => setToasts(([, ...toasts]) => toasts),
                DELAY
            );
            return () => clearTimeout(timer);
        }
        return;
    }, [toasts]);

    return (<ToastContext.Provider value={{ showErrorToast, showInfoToast, showWarnToast }}>
        {props.children}
        {toasts.map((toast, index) => (
            <ToastContainer backgroundColor={toast.backgroundColor} color={toast.color} delay={DELAY} key={`toast-${index}`}>
                {toast.content}
            </ToastContainer>
        ))}
    </ToastContext.Provider>);
};

const ToastProvider = withTheme(Toast);

export { ToastProvider };
