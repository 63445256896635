import { useState, useEffect } from 'react';
import { useAuth0 } from '@middleware/authorization';

type Method = 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';

interface FetchApiParams<T> {
    method: Method;
    path: string;
    token: string;
    data?: object | T;
}

interface UploadFileFetchApi {
    path: string;
    body: FormData;
    token: string;
}

const fetchApi = async <T>({ method, path, data, token }: FetchApiParams<T>) => {
    const body = data && JSON.stringify(data);
    return await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/${path}`, {
        method,
        body,
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            authorization: `Bearer ${token}`,
        }),
    });
};

export const uploadFile = async ({ path, body, token }: UploadFileFetchApi) => {
    return await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/${path}`, {
        body,
        method: 'POST',
        headers: new Headers({
            authorization: `Bearer ${token}`,
        }),
    });
};

export const downloadFile = async ({ path, token }: { path: string, token: string }) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/${path}`, {
        headers: new Headers({
            authorization: `Bearer ${token}`,
        }),
    });
    return await response.blob();
};

export interface QuerySortProps {
    sortField?: string;
    sortDirection?: 'ASC' | 'DESC';
}

export interface QueryDateRange {
    dateField?: string;
    from?: string;
    to?: string;
}

export const QueryPathFieldsDefaultValues: QueryPathFields = {
    sortField: undefined,
    sortDirection: undefined,
    dateField: undefined,
    from: undefined,
    to: undefined,
    page: 1,
    pageSize: 30,
};

export interface QueryPathFields extends QuerySortProps, QueryDateRange {
    page?: number;
    pageSize?: number;
    [key: string]: string | number | undefined;
}

export interface FetchAuthedApiRequest<T> {
    method?: Method;
    path: string;
    data?: object | T;
    token?: string;
    parseResult?: (jsonResult: any) => Promise<T>;
    forceUpdate?: boolean;
}

export interface AuthedApiResult<T> {
    data?: T;
    isLoading: boolean;
    error?: string;
    rawQuery?: string;
    refresh: () => void;
}

export const useFetchAuthedApi = <T>({ path, data, parseResult,
    method = 'GET', forceUpdate = false }: FetchAuthedApiRequest<T>): AuthedApiResult<T> => {
    const { token } = useAuth0();
    const [response, setResponse] = useState<T | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>(undefined);

    const apiFetcher = () => {
        const parser = parseResult || ((response) => Promise.resolve(response));

        if (!token) {
            setError('No auth token available');
            setResponse(undefined);
            setIsLoading(false);
        }

        setIsLoading(true);
        setError(undefined);
        fetchApi({ method, path, data, token })
            .then((response) => {
                return response.ok === true ? response.json() :
                    response.json().then(data => setError(JSON.stringify(data)));
            })
            .then(parser)
            .then(data => {
                setResponse(data);
            })
            .catch(error => {
                setResponse(undefined);
                setError(error.toString());
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(apiFetcher, [method, path, data, token, parseResult, forceUpdate]);

    return { data: response, isLoading, error, refresh: apiFetcher };
};

export interface AuthedPromiseApiResult<T> {
    data?: T;
    error?: string;
}

export const fetchAuthedApi = async <T>({ path, data, parseResult,
    token, method = 'GET' }: FetchAuthedApiRequest<T>): Promise<AuthedPromiseApiResult<T>> => {

    if (!token) {
        throw new Error('No auth token available');
    }

    try {
        const response = await fetchApi({ method, path, data, token });
        const json = await response.json();
        if (response.ok === true) {
            return {
                data: parseResult ? await parseResult(json) : json,
            };
        } else {
            return { error: json.error || json.message };
        }
    }
    catch (e) {
        console.log(e);
        return {
            error: e,
        };
    }
};
