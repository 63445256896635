import React, { useEffect, useState } from 'react';
import { Flex, Box, Text } from 'rebass/styled-components';
import {
    Table, TableContainer, MainContainer, TableEmpty, TableBody,
} from './components/table.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { QuerySortProps, QueryDateRange, QueryPathFields } from '@providers/apiProvider';
import { ColumnProps, SortType, TableHeader } from './TableHeader';
import { TableFilter } from './tableFilter';
import { TablePagination } from './TablePagination';
import { HighlightColor, TableContent } from './TableContent';
import { TableSizeSelector } from './TableSizeSelector';

export const TableDefaultValues: TableProps = {
    data: [],
    indexId: '',
    total: 0,
    columns: undefined,
    defaultSort: undefined,
    rowClickHandler: undefined,
    updateHandler: undefined,
    loading: undefined,
    highlightedRows: {},
};

export interface TableProps {
    data: { [key: string]: any; }[];
    indexId: string;
    total?: number;
    columns?: ColumnProps[];
    defaultPage?: number;
    defaultPageSize?: number;
    defaultSort?: QuerySortProps;
    loading?: boolean;
    highlightedRows?: { [key: string]: HighlightColor };
    rowClickHandler?: (indexId: string) => void;
    selectedRowHandler?: (indexId: string, checked: boolean) => void;
    updateHandler?: (fields: QueryPathFields) => void;
}

const TableComponent = ({ data, indexId, total, columns, defaultPage, defaultPageSize, defaultSort,
    loading, highlightedRows, updateHandler, rowClickHandler, selectedRowHandler }: TableProps) => {

    const [pageSize, setPageSize] = useState<number>(Number(defaultPageSize) || 30);
    const [page, setPage] = useState<number>(Number(defaultPage) || 1);
    const [dataSize, setDataSize] = useState<number>(0);
    const [tableData, setTableData] = useState<{ [key: string]: any; }[]>([]);
    const [filter, setFilter] = useState<{ [key: string]: string }>({});
    const [sort, setSort] = useState<SortType>({
        sortField: defaultSort && defaultSort.sortField || '',
        sortDirection: defaultSort && defaultSort.sortDirection || 'ASC',
    });

    useEffect(() => {
        setTableData(data);
        setDataSize(total || data.length);
    }, [indexId, data]);

    const handleUpdate = (page: number, pageSize: number,
        sort: QuerySortProps, filter: { [key: string]: string }): void => {
        if (updateHandler) {
            const { dateField, from, to, ...query } = filter;
            const dateRange: QueryDateRange = {
                dateField,
            };
            if (from) dateRange['from'] = from;
            if (to) dateRange['to'] = to;
            updateHandler({
                page,
                pageSize,
                ...sort,
                ...query,
                ...dateRange,
            });
        }
    };

    const handleChangePageSize = (pageSize: number): void => {
        const max = Math.ceil(dataSize / pageSize);
        const maxPage = page > max ? max : page;
        setPage(maxPage);
        setPageSize(pageSize);
        handleUpdate(maxPage, pageSize, sort, filter);
    };

    const handleSetPage = (newPage: number): void => {
        const max = Math.ceil(dataSize / pageSize);
        if (newPage < 1 || newPage > max) return;
        setPage(newPage);
        handleUpdate(newPage, pageSize, sort, filter);
    };

    const handleSetSort = (key: string): void => {
        function toggleSort(dir: 'ASC' | 'DESC'): 'DESC' | 'ASC' {
            if (dir === 'ASC') {
                return 'DESC';
            } else {
                return 'ASC';
            }
        }

        const newSort: SortType = {
            sortField: key,
            sortDirection: sort.sortField === key ? toggleSort(sort.sortDirection) : 'ASC',
        };

        setSort(newSort);
        handleUpdate(page, pageSize, newSort, filter);
    };

    const filterList = (newFilter: { [key: string]: string }, filter: { [key: string]: string }): void => {
        Object.entries(newFilter).forEach(([key, value]) => {
            if (filter[key] && !value) {
                delete filter[key];
            } else {
                filter[key] = value;
            }
        });

        setFilter(filter);
        handleUpdate(page, pageSize, sort, filter);
    };

    const getHeaderColumns = (): ColumnProps[] =>
        selectedRowHandler ? [{
            id: 'check',
            displayName: '',
            filterType: undefined,
            formatter: undefined,
        }, ...getColums()] : getColums();

    const getColums = (): ColumnProps[] => {
        if (columns && columns.length > 0) {
            return columns;
        } else if (data && data.length > 0) {
            return Object.keys(data[0]).map((key: string) => ({
                id: key,
                displayName: key,
                filterType: undefined,
                formatter: undefined,
            }));
        }
        return [];
    };

    return (
        <MainContainer>
            <TableContainer>
                <Table>
                    <TableHeader columns={getHeaderColumns()} onSetSort={handleSetSort}
                        sortField={sort.sortField} sortDirection={sort.sortDirection} />
                    <TableBody>
                        <TableFilter
                            columns={getHeaderColumns()}
                            filterList={(newFilter) => filterList(newFilter, filter)} />
                        <TableContent indexId={indexId} rows={loading ? [] : tableData} columns={getColums()}
                            highlightedRows={highlightedRows} onSelectRow={selectedRowHandler}
                            onClicRow={rowClickHandler} />
                    </TableBody>
                </Table>
                {loading && <TableEmpty>
                    <FontAwesomeIcon icon={faSpinner} size='3x' pulse />
                </TableEmpty>}
                {/* If no data exists with the applied filters or in the table at all, display "No Results Found" */}
                {!loading && dataSize === 0 && <TableEmpty>
                    <Text>No Results Found</Text>
                </TableEmpty>}
            </TableContainer>
            <Flex alignItems='center' flexWrap='wrap'>
                <Box width={1 / 3}>
                    <TableSizeSelector pageSize={pageSize} dataSize={dataSize}
                        onChangePageSize={handleChangePageSize} />
                </Box>
                <Box ml='auto'>
                    {dataSize > pageSize &&
                        <TablePagination
                            page={page}
                            dataSize={dataSize}
                            pageSize={pageSize}
                            onSetPage={handleSetPage} />}
                </Box>
            </Flex>
        </MainContainer>
    );
};

export default TableComponent;
