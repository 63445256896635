import React from 'react';
import { TableBodyRow, TableDataCell } from './components/table.styled';
import { Checkbox, Label } from '@rebass/forms';
import { ColumnProps } from './TableHeader';

interface TableContentProps {
    indexId: string;
    rows: { [key: string]: any; }[];
    columns: ColumnProps[];
    highlightedRows?: { [key: string]: HighlightColor };
    onClicRow?: (indexId: string) => void;
    onSelectRow?: (indexId: string, checked: boolean) => void;
}

export enum HighlightColor {
    RED = 'RED',
    ORANGE = 'ORANGE',
    YELLOW = 'YELLOW',
    WHITE = 'WHITE',
}

export function highlightAs(keys: string[], color: HighlightColor) {
    return keys.reduce((accum, key) => ({ ...accum, [key]: color }), {});
}

export const TableContent = ({ indexId, rows, columns, highlightedRows, onClicRow, onSelectRow }: TableContentProps) =>
    <>
        {rows.map((row: { [key: string]: any; }, index: number) => {
            const id: string = String(row[indexId]);

            const renderedRow: JSX.Element[] = columns.map((column: ColumnProps, index: number) =>
                <TableDataCell key={`${column.id}-${id}-${index}`}>
                    {column.formatter ? column.formatter(row[column.id]) : row[column.id]}
                </TableDataCell>);

            return (
                <TableBodyRow key={`${id}-${index}`}
                    onClick={onClicRow ? () => onClicRow(id) : undefined}
                    highlight={(highlightedRows || {})[id]}>
                    {onSelectRow && <TableDataCell
                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                            e.stopPropagation();
                        }} >
                        <Label htmlFor={`check-${id}`}>
                            <Checkbox id={`check-${id}`} bg='white'
                                name={`check-${id}`}
                                value={id}
                                onChange={({ target: { value, checked } }:
                                    React.ChangeEvent<HTMLInputElement>) => {
                                    onSelectRow(value, checked);
                                }} />
                        </Label>
                    </TableDataCell>}
                    {renderedRow}
                </TableBodyRow >
            );
        })}
    </>;
