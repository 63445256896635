import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box } from 'rebass/styled-components';
import { EinDetailHeader } from '@components/ein/EinDetailHeader';
import { useGetEINById } from '@providers/ein';
import { Ein } from '@providers/types';
import { EmployeeList } from '@components/employee/EmployeeList';
import { getObjFromQuery, getRawQuery } from '@utils/queryParams';

const EinDetail = (): JSX.Element => {
    const { einId }: { einId: string } = useParams();
    const history = useHistory();
    const [query, setQuery] = useState({
        ...(history.location.search ?
            getObjFromQuery(history.location.search) : {
                page: 1, pageSize: 30,
            })
    });
    useEffect(() => {
        history.push(`${history.location.pathname}?${getRawQuery(query)}`);
    }, [query]);

    const { data: apiEin, isLoading, error }: {
        data?: Ein,
        isLoading?: boolean, error?: string
    } = useGetEINById(einId);

    useEffect(() => {
        apiEin && setQuery({ ...query, employeeEinName: apiEin.legalName });
    }, [apiEin]);

    return (
        <Box sx={{
            mx: 'auto',
            px: 3,
        }}>
            {isLoading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {apiEin && <EinDetailHeader ein={apiEin} />}
            <hr />
            <EmployeeList query={query} updateQuery={setQuery} />
        </Box>
    );
};

export default EinDetail;
