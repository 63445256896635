import { useFetchAuthedApi, AuthedApiResult, QueryPathFields } from './apiProvider';
import { Payroll, PayrollDefaultValue } from './types';
import { getRawQuery, validateQueryDefinition } from '@utils/queryParams';

export const useGetPayrolls = (params: QueryPathFields)
    : AuthedApiResult<{ data: Payroll[], total: number }> => {
    validateQueryDefinition<Payroll & { alertId: number }>(params, { ...PayrollDefaultValue, alertId: -1 });
    return useFetchAuthedApi<{ data: Payroll[], total: number }>({
        path: `payrolls?${getRawQuery(params)}`,
    });
};
