import { Alert, User } from '@providers/types';

export interface WhitelistRule {
    id?: number;
    alertType: { name: string, id: number };
    companyName: string;
    employeeId?: string;
    companyId?: string;
    EIN?: string;
    createdBy: User | null;
    reason: string;
    whitelistedAlerts?: Alert[];
    deletedAt?: string;
    createdDate?: string;
    updatedDate?: string;
}

export const WhitelistRuleDefaultValue: WhitelistRule = {
    id: -1,
    alertType: { name: '', id: -1 },
    companyName: '',
    employeeId: '',
    companyId: '',
    EIN: '',
    createdBy: null,
    reason: '',
    whitelistedAlerts: [],
    deletedAt: '',
    createdDate: '',
    updatedDate: '',
};
