import React from 'react';
import { Flex, Box, Text } from 'rebass/styled-components';

interface GenericAlertTypeDetailWrapperProps {
    alertTypeDetail: React.ReactNode[] | React.ReactNode;
    leftAlertTypeDetail: React.ReactNode[] | React.ReactNode;
    children: React.ReactNode[] | React.ReactNode;
}

const GenericAlertTypeDetailWrapper = ({ alertTypeDetail,
    children, leftAlertTypeDetail }: GenericAlertTypeDetailWrapperProps): JSX.Element => {

    return <>
        <Flex p={30} pr={16} pl={16} mb={40}>
            <Box minWidth='auto' pr={20} width={1 / 3}>
                {leftAlertTypeDetail}
            </Box>
            <hr />
            <Box minWidth='auto' pl={20} width={1}>
                <Text fontSize={30} fontWeight={600}>Alert Description</Text>
                {alertTypeDetail}
            </Box>
        </Flex>
        {children}
    </>;
};

export { GenericAlertTypeDetailWrapper };
