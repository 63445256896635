import React from 'react';
import { JobsList } from '@components/job/JobsList';
import { DataLoader } from '@components/dataloader';
import { useGetJobs } from '@providers/job';

const JobsView = (): JSX.Element => {
    const data = useGetJobs();
    return <DataLoader
        data={data}
        render={jobs => <JobsList jobs={jobs} />}
    />;
};

export default JobsView;
