import React, { useEffect, useState } from 'react';
import { InlineText } from '@components/styles/text.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { Box } from 'rebass/styled-components';
import { EmployeeList } from '@components/employee/EmployeeList';
import { useHistory } from 'react-router-dom';
import { getObjFromQuery, getRawQuery } from '@utils/queryParams';

const EmployeesList = (): JSX.Element => {
    const history = useHistory();
    const [query, setQuery] = useState({
        ...(history.location.search ?
            getObjFromQuery(history.location.search) : {
                page: 1, pageSize: 30,
            })
    });
    useEffect(() => {
        history.push(`${history.location.pathname}?${getRawQuery(query)}`);
    }, [query]);

    return (<section>
        <Box mr='40px'>
            <InlineText fontSize={27} ><FontAwesomeIcon icon={faUsers} /></InlineText>
            <InlineText fontSize={43}> Employees</InlineText>
        </Box>
        <EmployeeList query={query} updateQuery={setQuery} />
    </section>);
};

export default EmployeesList;
