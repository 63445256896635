import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TableComponent from '@components/table/Table';
import { useGetAlerts } from '@providers/alert';
import { QueryPathFields } from '@providers/apiProvider';
import { useGetUsers } from '@providers/user';
import { useGetAlertTypes } from '@providers/alert';
import { StatusEnum, RiskLevelEnum, CategoryEnum, AlertType, User, defaultUseGetAlertsFields } from '@providers/types';
import { AlertColumnFormatterDefinition } from './types/alert-table.types';
import { ColumnProps } from '@components/table/TableHeader';
import { TableFilterTypeEnum } from '@components/table/tableFilter';
import { highlightAs, HighlightColor } from '@components/table/TableContent';

interface AlertListProps {
    query?: QueryPathFields;
    updateQuery?: (params: QueryPathFields) => void;
}

const AlertList = ({ query, updateQuery }: AlertListProps): JSX.Element => {
    const stateQuery = query ?
        { ...defaultUseGetAlertsFields, ...query } : { ...defaultUseGetAlertsFields };
    const history = useHistory();
    const { data: apiUsers }: { data?: User[] } = useGetUsers();
    const [users, setUsers] = useState<{ id: string, value: string }[]>([]);
    const { data: apiAlertTypes }: { data?: AlertType[] } = useGetAlertTypes();
    const [alertTypes, setAlertTypes] = useState<{ id: string, value: string }[]>([]);
    const { isLoading, error, data: apiAlerts } = useGetAlerts(stateQuery);

    const redirectTransactionsList = (id: string) => {
        history.push(`/alerts/${id}`);
    };

    useEffect(() => {
        if (apiUsers) {
            const userSet: { id: string, value: string }[] = apiUsers.map((user: User) => (
                { id: user.id, value: user.name }
            ));
            setUsers(userSet);
        }
    }, [apiUsers]);

    useEffect(() => {
        if (apiAlertTypes) {
            const aTypesSet: { id: string, value: string }[] = apiAlertTypes.map((type: AlertType) => (
                { id: type.id.toString(), value: type.name }
            ));
            setAlertTypes(aTypesSet);
        }
    }, [apiAlertTypes]);

    const ALERTS_COLUMN_NAMES: ColumnProps[] = [
        {
            id: 'riskLevel', displayName: 'Priority',
            formatter: AlertColumnFormatterDefinition.riskFromatter,
            filterType: {
                type: TableFilterTypeEnum.SELECT,
                entity: Object.values(RiskLevelEnum).map((e: string) => ({ id: e, value: e })),
            },
        },
        {
            id: 'id', displayName: 'Case',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'category', displayName: 'Category',
            filterType: {
                type: TableFilterTypeEnum.SELECT,
                entity: Object.values(CategoryEnum).map((e: string) => ({ id: e, value: e })),
            },
        },
        {
            id: 'alertType', displayName: 'Type of alert',
            formatter: AlertColumnFormatterDefinition.alertTypeFormatter,
            filterType: { type: TableFilterTypeEnum.SELECT, entity: alertTypes },
        },
        {
            id: 'companyName', displayName: 'Client',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'createdDate', displayName: 'Created',
            formatter: AlertColumnFormatterDefinition.dateFormatter,
            filterType: { type: TableFilterTypeEnum.DATE },
        },
        {
            id: 'user', displayName: 'Assigned to',
            formatter: AlertColumnFormatterDefinition.genericFormatter,
            filterType: { type: TableFilterTypeEnum.SELECT, entity: users },
        },
        {
            id: 'status', displayName: 'Status',
            formatter: AlertColumnFormatterDefinition.statusFromatter,
            filterType: {
                type: TableFilterTypeEnum.SELECT,
                entity: Object.values(StatusEnum).map((e: string) => ({ id: e, value: e })),
            },
        },
    ];

    return (
        <section>
            {error && <p>Error: {error}</p>}
            {apiAlerts && apiAlerts.data && <TableComponent
                indexId='id'
                data={apiAlerts.data}
                total={apiAlerts.total || apiAlerts.data.length}
                columns={ALERTS_COLUMN_NAMES}
                loading={isLoading}
                updateHandler={updateQuery}
                defaultPage={stateQuery.page}
                defaultPageSize={stateQuery.pageSize}
                defaultSort={{ sortField: stateQuery.sortField, sortDirection: stateQuery.sortDirection }}
                highlightedRows={{
                    ...highlightAs(
                        apiAlerts.data
                            .filter(data => data.riskLevel === RiskLevelEnum.HIGH)
                            .map(data => data.id.toString()),
                        HighlightColor.RED),
                    ...highlightAs(
                        apiAlerts.data
                            .filter(data => data.riskLevel === RiskLevelEnum.MEDIUM)
                            .map(data => data.id.toString()),
                        HighlightColor.YELLOW),
                }}
                rowClickHandler={redirectTransactionsList} />}
        </section>
    );
};

export { AlertList };
