export interface Ein {
    id: string;
    kronosId: string;
    legalName: string;
    companyEINId: string;
    companyShortName: string;
    state: string;
    createdDate: string;
    active: boolean;
    openAlerts: number;
    closedAlerts: number;
}

export const EinDefaultValue: Ein = {
    id: '',
    kronosId: '',
    legalName: '',
    companyEINId: '',
    companyShortName: '',
    openAlerts: -1,
    closedAlerts: -1,
    state: '',
    createdDate: '',
    active: false,
};
