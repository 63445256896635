import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSave } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '@components/modal/Modal';
import { ModalSaveButton } from '@components/modal/components/modal.styled';
import { Button } from 'rebass/styled-components';
import { useAuth0 } from '@middleware/authorization';
import { restoreWhitelistRules } from '@providers/whitelistRule';
import { useHistory } from 'react-router-dom';

const RestoreRules = ({ whitelistRuleIds }: { whitelistRuleIds: string[] }): JSX.Element => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const { token } = useAuth0();
    const history = useHistory();

    const handleDelete = (): void => {
        setShowModal(false);
        restoreWhitelistRules(whitelistRuleIds, token).then(() => history.push(`/whitelistrules`));
    };

    return (
        <>
            <Modal visible={showModal} setVisible={setShowModal}
                header={<><FontAwesomeIcon icon={faTrash} /> Restore whitelis rules</>}
                footer={<ModalSaveButton onClick={handleDelete}>
                    <FontAwesomeIcon icon={faSave} /> &nbsp; Restore
                </ModalSaveButton>}>
                Restore ({whitelistRuleIds.length}) rules?
            </Modal>
            <Button variant='primary' mr={2} p={3} onClick={() => setShowModal(!showModal)}>
                <FontAwesomeIcon icon={faTrash} /> Restore whitelist rules
            </Button>
        </>
    );
};

export { RestoreRules };
