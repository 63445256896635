import styled, { css } from 'styled-components';
import { Text } from 'rebass/styled-components';
import { TooltipPositionEnum } from './types/tooltip.types';

const arrowBaseCss = css`
    content: " ";
    position: absolute;
    border-width: 5px;
    border-style: solid;
`;

const selectDirection = {
    TOP: css`
        bottom: 105%;
        left: 50%;
        margin-left: -50%;
        &::after {
            ${arrowBaseCss}
            top: 100%;
            left: 25%;
            margin-left: -5px;
            border-color: ${({ theme }) => theme.colors.primary} transparent transparent transparent;
        }
    `,
    BOTTOM: css`
        top: 105%;
        left: 50%;
        margin-left: -50%;
        &::after {
            ${arrowBaseCss}
            bottom: 100%;
            left: 25%;
            margin-left: -5px;
            border-color: transparent transparent ${({ theme }) => theme.colors.primary} transparent;
        }
    `,
    LEFT: css`
        right: 105%;
        transform: translateY(-50%);
        &::after {
            ${arrowBaseCss}
            top: 40%;
            left: 100%;
            margin-top: -5px;
            border-color: transparent transparent transparent ${({ theme }) => theme.colors.primary};
        }
    `,
    RIGHT: css`
        left: 105%;
        transform: translateY(-50%);
        &::after {
            ${arrowBaseCss}
            top: 40%;
            right: 100%;
            margin-top: -5px;
            border-color: transparent ${({ theme }) => theme.colors.primary} transparent transparent;
        }
    `,
};

export const TooltipBox = styled.div<{ position: TooltipPositionEnum, isOpen: Boolean }>`
    opacity: 0;
    visibility: hidden;
    position: absolute;
    display: flex;
    z-index: 1;
    transition: 0.3s;
    padding: 14px 20px;
    width: max-content;
    border-radius: 8px;
    background-color: #22263A;
    box-shadow: 0 0 14px 0 rgba(0,0,0,0.09);

    ${({ position }) => selectDirection[position]}

    ${({ isOpen }) => isOpen && css`
        visibility: visible;
        opacity: 1;
    `}
`;

export const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;
`;

export const TooltipText = styled(Text)`
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors.background};
`;
