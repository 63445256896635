import { useFetchAuthedApi, AuthedApiResult, fetchAuthedApi } from './apiProvider';

export interface Job {
    id: string;
    name: string;
    enabled: boolean;
    lastRun: Date;
    data: object;
    lastError: string | null;
}

export const useGetJobs = (): AuthedApiResult<Job[]> => {
    return useFetchAuthedApi({ path: `jobs` });
};

export const useGetJobById = (jobId: string): AuthedApiResult<Job> => {
    return useFetchAuthedApi({
        path: `jobs/${encodeURIComponent(jobId)}`,
        parseResult: (data) => ({
            ...data,
            lastRun: new Date(data.lastRun)
        })
    });
};

export const patchJob = async (job: Job, token: string): Promise<void> => {
    await fetchAuthedApi({
        token,
        method: 'PATCH',
        path: `jobs/${job.id}`,
        data: { ...job },
    });
};
