import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { Link as RebassLink } from 'rebass/styled-components';

const Link = (props: { [key: string]: any }) =>
    <RebassLink
        {...props}
        as={RouterLink}
    />;

export const StyledLink = styled(Link)`
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.grayLight};
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
`;
