import React from 'react';
import { Select as RebassSelect } from '@rebass/forms';

interface SelectProps {
    options: string[];
    onSelect: (value: string) => void;
}

export const Select = ({ options, onSelect }: SelectProps): JSX.Element =>
    <RebassSelect bg='white' display='inline'
        onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => onSelect(value)} >
        {options.map((value: string) =>
            <option key={`option-${value}`} value={value}>
                {value}
            </option>
        )}
    </RebassSelect>;
