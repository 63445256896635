import { QueryPathFields, QueryPathFieldsDefaultValues } from '@providers/apiProvider';

export enum PayrollTypesEnum {
    Historical = 'Historical',
    Reconciliation = 'Reconciliation',
    Regular = 'Regular',
    Void = 'Void',
    Supplemental = 'Supplemental',
    Correction = 'Correction',
    Historical_void = 'Historical void',
    FUTA_Credit_Reduction = 'FUTA Credit Reduction',
    Reconciliation_Historical = 'Reconciliation (Historical)',
    Parallel = 'Parallel',
}

export interface Payroll {
    payrollName: string;
    companyId: string;
    payrollCumulativeGross: number;
    finalizedDate: string;
    EIN: string;
    payDate: string;
    payStatementNet: number;
}

export const PayrollDefaultValue: Payroll = {
    payrollName: '',
    companyId: '',
    payrollCumulativeGross: -1,
    finalizedDate: '',
    EIN: '',
    payDate: '',
    payStatementNet: -1,
};

export const defaultUseGetPayrollsFields: QueryPathFields = {
    ...QueryPathFieldsDefaultValues,
    sortField: 'payDate',
    sortDirection: 'DESC',
};
