import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box } from 'rebass/styled-components';
import { AlertDetailHeader } from '@components/alert/AlertDetailHeader';
import { CommentsList } from '@components/comment/CommentsList';
import { AlertOptionsFooter } from '@components/alert/AlertOptionsFooter';
import { useGetAlertByCaseNumber } from '@providers/alert';
import { useGetComments } from '@providers/comment';
import { QueryPathFields } from '@providers/apiProvider';
import { getObjFromQuery, getRawQuery } from '@utils/queryParams';
import { AlertTypeDetail } from '@components/alert-type';

const AlertDetail = (): React.ReactElement => {
    const { caseNumber }: { caseNumber: string } = useParams();
    const history = useHistory();
    const [query, setQuery] = useState({
        ...(history.location.search ?
            getObjFromQuery(history.location.search) :
            { alertId: caseNumber })
    });
    useEffect(() => {
        history.push(`${history.location.pathname}?${getRawQuery(query)}`);
    }, [query]);

    const updateQuery = (params: QueryPathFields): void => {
        setQuery({
            ...params,
            alertId: caseNumber,
        });
    };

    const { data: apiAlert, refresh: refreshAlert } = useGetAlertByCaseNumber(caseNumber);
    const { data: apiComments, refresh: refreshComments } = useGetComments(caseNumber);
    const refresh = () => {refreshAlert(); refreshComments();};

    return (
        <Box sx={{
            mx: 'auto',
            px: 3,
        }}>
            {apiAlert && <>
                <AlertDetailHeader alert={apiAlert} setForceUpdate={refresh} />
                <AlertTypeDetail alert={apiAlert} query={query}
                    updateQuery={updateQuery} />
                {apiComments && <CommentsList comments={apiComments} />}
                <AlertOptionsFooter alert={apiAlert} setForceUpdate={refresh} />
            </>}
        </Box>
    );
};

export default AlertDetail;
