import styled, { keyframes } from 'styled-components';

const fadein = keyframes`
    from {top: 0; opacity: 0;} 
    to {top: 55px; opacity: 1;}
`;

const fadeout = keyframes`
    from {top: 55px; opacity: 1;}
    to {top: 0; opacity: 0;}
`;

export const ToastContainer = styled.div < { delay: number; backgroundColor: string; color: string; } > `
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ color }) => color};
    animation: ${fadein} 0.5s, ${fadeout} 0.5s ${({ delay }) => delay - 0.5}s;
    visibility: visible;
    box-shadow: 0px 0px 10px 5px rgba(89,89,89,0.5);
    min-width: 250px;
    margin-left: -125px;
    text-align: center;
    border-radius: 8px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    top: 55px;
`;
