import React from 'react';
import TableComponent from '@components/table/Table';
import { useGetLicenses } from '@providers/license';
import { QueryPathFields } from '@providers/apiProvider';
import { License, LicenseStatusEnum } from '@providers/types/license.types';
import { dateFormat } from '@utils/formatting';
import { ColumnProps } from '@components/table/TableHeader';
import { TableFilterTypeEnum } from '@components/table/tableFilter';

interface LicenceListProps {
    query?: QueryPathFields;
    updateQuery?: (params: QueryPathFields) => void;
    handleShowLicenseInfo: (id: string) => void;
    forceUpdate: boolean;
}

const LicenseList = ({ query, updateQuery,
    handleShowLicenseInfo, forceUpdate }: LicenceListProps): JSX.Element => {
    const { isLoading, error, data: apiLicences }: {
        data?: { data: License[], total: number },
        error?: string, isLoading: boolean,
    } = useGetLicenses(query || {}, forceUpdate);

    const LICENCES_COLUMN_NAMES: ColumnProps[] = [
        {
            id: 'state', displayName: 'State',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'type', displayName: 'License type',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'licenseId', displayName: 'License',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'einName', displayName: 'EIN legal name',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'companyName', displayName: 'DBA name',
            filterType: {
                type: TableFilterTypeEnum.INPUT,
            },
        },
        {
            id: 'expirationDate', displayName: 'Expiration date',
            formatter: dateFormat,
        },
        {
            id: 'status', displayName: 'Status',
            filterType: {
                type: TableFilterTypeEnum.SELECT,
                entity: Object.values(LicenseStatusEnum).map((e: string) => ({ id: e, value: e })),
            },
        },
    ];

    return (
        <section>
            {error && <p>Error: {error}</p>}
            {apiLicences && <TableComponent
                indexId='id'
                data={apiLicences.data}
                total={apiLicences.total || apiLicences.data.length}
                columns={LICENCES_COLUMN_NAMES}
                loading={isLoading}
                rowClickHandler={handleShowLicenseInfo}
                defaultPage={query && query.page}
                defaultPageSize={query && query.pageSize}
                defaultSort={query && { sortField: query.sortField, sortDirection: query.sortDirection }}
                updateHandler={updateQuery} />}
        </section>
    );
};

export { LicenseList };
