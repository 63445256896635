import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThList } from '@fortawesome/free-solid-svg-icons';
import { Flex, Box, Text } from 'rebass/styled-components';
import { InlineHeading, InlineText } from '@components/styles/text.styled';
import { Company } from '@providers/types';

const CompanyDetailHeader = ({ company }: { company: Company }): JSX.Element =>
    <Box mt={45}>
        <Flex justifyContent='space-between' alignItems='center' pb={15}>
            <Box>
                <InlineHeading fontSize={28} color='prmary'>
                    <FontAwesomeIcon icon={faThList} /> &nbsp; <b>Account detail |</b>
                </InlineHeading>
                <InlineHeading fontSize={28} fontWeight={400}> {company.id}</InlineHeading>
            </Box>
        </Flex>
        <Flex p={30} pr={16} pl={16}>
            <Box width={1 / 2} pr={20}>
                <Text fontSize={36} fontWeight='bold' pb={10} pt={10}>
                    {company.name}
                </Text>
                <Text fontSize={18} pt={20} minHeight={96}>
                    <b>Payroll Status</b> {company.status}
                </Text>
            </Box>
            <hr />
            <Flex width={1 / 2} pl={20}>
                <Flex width={1 / 2} flexDirection='column' justifyContent='center'>
                    <InlineText fontSize={18} >Shortname:&nbsp;<b>{company.shortName}</b></InlineText>
                    <InlineText fontSize={18} >Account state:&nbsp;<b>{company.state}</b></InlineText>
                    <InlineText fontSize={18} >Active since:&nbsp;<b>{/*MISSING DATA*/}</b></InlineText>
                </Flex>
                <Flex width={1 / 2} flexDirection='column' justifyContent='center'>
                    <InlineText fontSize={18} >HR status:&nbsp;<b>{/*MISSING DATA*/}</b></InlineText>
                    <InlineText fontSize={18} >TLM status:&nbsp;<b>{/*MISSING DATA*/}</b></InlineText>
                    <InlineText fontSize={18} >Tax ID:&nbsp;<b>{/*MISSING DATA*/}</b></InlineText>
                    <InlineText fontSize={18} >DBA:&nbsp;<b>{/*MISSING DATA*/}</b></InlineText>
                </Flex>
            </Flex>
        </Flex>
    </Box>;

export { CompanyDetailHeader };
