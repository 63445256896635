import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { Flex, Box, Text } from 'rebass/styled-components';
import { InlineHeading, InlineText } from '@components/styles/text.styled';
import { Employee } from '@providers/types';
import { dateFormat } from '@utils/formatting';
import { StyledLink } from '@components/styles/link.styled';

const EmployeeDetailHeader = ({ employee }: { employee: Employee }): JSX.Element =>
    <Box mt={45}>
        <Flex justifyContent='space-between' alignItems='center' pb={15}>
            <Box>
                <InlineHeading fontSize={28} color='prmary'>
                    <FontAwesomeIcon icon={faUsers} /> &nbsp; <b>Employee detail |</b>
                </InlineHeading>
                <InlineHeading fontSize={28} fontWeight={400}> {employee.id}</InlineHeading>
            </Box>
        </Flex>
        <Flex p={30} pr={16} pl={16}>
            <Box width={1 / 2} pr={20}>
                <Text fontSize={36} fontWeight='bold' pb={10} pt={10}>
                    {employee.employeeFirstName} {employee.employeeLastName}
                </Text>
                <Text fontSize={18} pt={20}>
                    <b>Account</b> <StyledLink to={`/companies/${employee.companyDbId}`}>
                        {employee.companyKronosId}
                    </StyledLink>
                </Text>
                <Text fontSize={18} minHeight={86}>
                    <b>EIN Name </b> {employee.employeeEinName}
                </Text>
            </Box>
            <hr />
            <Flex width={1 / 2} pl={20}>
                <Flex width={1 / 2} flexDirection='column' justifyContent='center'>
                    <InlineText fontSize={18} >
                        Hire date:&nbsp;<b>{dateFormat(employee.employeeHiredDate || '')}</b>
                    </InlineText>
                    <InlineText fontSize={18} >
                        Terminated date:&nbsp;<b>{dateFormat(employee.employeeTerminatedDate || '')}</b>
                    </InlineText>
                    <InlineText fontSize={18} >
                        Rehired date:&nbsp;<b>{dateFormat(employee.employeeRehiredDate || '')}</b>
                    </InlineText>
                </Flex>
                <Flex width={1 / 2} flexDirection='column' justifyContent='center'>
                    <InlineText fontSize={18} >
                        Job title:&nbsp;<b>{employee.employeeJobTitle}</b>
                    </InlineText>
                    <InlineText fontSize={18} >
                        Current work state:&nbsp;<b>{employee.employeeAddressState}</b>
                    </InlineText>
                </Flex>
            </Flex>
        </Flex>
    </Box>;

export { EmployeeDetailHeader };
