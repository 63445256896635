import styled from 'styled-components';
import { Box } from 'rebass/styled-components';

export const CommentContainer = styled(Box)`
    position: relative;
    display: flex;
    margin: 20px 0 0 0;
	padding: 20px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.muted};

    &:before, :after{
	    content: ' ';
	    position: absolute;
	    width: 0;
	    height: 0;
    }

    &:before {
        left: 54px;
        top: -15px;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid ${({ theme }) => theme.colors.gray};
    }

    &:after {
        left: 55px;
        top: -14px;
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-bottom: 14px solid ${({ theme }) => theme.colors.muted};
    }
`;
