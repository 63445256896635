import React from 'react';
import { Flex, Box, Text } from 'rebass/styled-components';
import { Hr } from './components/companySummary';
import { useGetCompany, useCountCompanyAlerts } from '@providers/company';
import { Company } from '@providers/types';

const CompanySummary = ({ companyId }: { companyId: string }): JSX.Element => {
    const { data: company }: { data?: Company } = useGetCompany(companyId);
    const { data: activeAlerts }: { data?: { openAlerts: number } } = useCountCompanyAlerts(companyId);

    return (company ? <Flex flexDirection='column'>
        <Box minWidth='auto' color='background'>
            {company.id && <Text>Account ID <b>{company.kronosId}</b></Text>}
            {company.name && <Text>Account Name <b>{company.name}</b></Text>}
            {company.shortName && <Text>Shortname <b>{company.shortName}</b></Text>}
        </Box>
        <Hr />
        <Box minWidth='auto'>
            {company.numberOfEINs && <Text>EIN’s <b>{company.numberOfEINs}</b></Text>}
            {activeAlerts && <Text>Active alerts <b>{activeAlerts.openAlerts}</b></Text>}
            {company.employeesOnPayroll && <Text># of employees <b>{company.employeesOnPayroll}</b></Text>}
        </Box>
    </Flex> : <Text><b>Not avalaible data</b></Text>);
};

export { CompanySummary };
