import React, { useState, useEffect } from 'react';
import { Comment, CommentDefaultValue, CommentTemplate, CommentTemplateDefaultValue } from '@providers/types';
import { useGetCommentTemplates } from '@providers/commentTemplate';
import { CommentTemplateList } from './components/CommentTemplateList';
import { CommentTextarea } from './components/CommentTextarea';

export interface CommentFormProps {
  handleCommentChange: (comment: Comment) => void;
}

const CommentForm : React.FC<CommentFormProps> = ({ handleCommentChange }) => {

    const { data: commentTemplates }: { data?: CommentTemplate[] } = useGetCommentTemplates();
    const [selectedCommentTemplate, setSelectedCommentTemplate] =
        useState<CommentTemplate | null | undefined>(undefined);
    const [customComment, setCustomComment] = useState<string>('');

    useEffect(() => {
      if(selectedCommentTemplate) {
        handleCommentChange({
            ...CommentDefaultValue,
            title: selectedCommentTemplate.title,
            body: selectedCommentTemplate.body,
        });
      } else {
        handleCommentChange({
            ...CommentDefaultValue,
            title: CommentTemplateDefaultValue.title,
            body: customComment,
        });
      }
    }, [selectedCommentTemplate, customComment]);

    return (
        <>
            {commentTemplates && <CommentTemplateList
                                    templates={commentTemplates}
                                    selected={selectedCommentTemplate}
                                    onSelect={setSelectedCommentTemplate} /> }
            <CommentTextarea
                disabled={selectedCommentTemplate ? true : false}
                onChange={({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setCustomComment(value)} />
        </>
    );
};

export { CommentForm };
