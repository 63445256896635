import React from 'react';
import Loading from '@components/loading/Loading';
import { AuthedApiResult } from '@providers/apiProvider';

interface DataLoaderProps<T> {
    data: AuthedApiResult<T>;
    render: (item: T) => React.ReactElement;
}

export function DataLoader<T>({ data, render }: DataLoaderProps<T>): React.ReactElement {
    return <>
        {data.isLoading && <Loading />}
        {data.error && <p>Error: {data.error}</p>}
        {data.data && render(data.data)}
    </>;
}
