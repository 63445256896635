export enum LicenseStatusEnum {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    APPLIED = 'APPLIED',
    EXPIRED = 'EXPIRED',
    INACTIVE = 'INACTIVE',
    NOT_FOUND = 'NOT-FOUND',
}

export interface License {
    id?: number;
    licenseId: string;
    state: string;
    type: string;
    einName: string;
    einId: string;
    companyName: string;
    expirationDate: string;
    lastVerifiedDate: string;
    createdDate?: string;
    updatedDate?: string;
    status: LicenseStatusEnum;
    filepath?: string;
}

export const LicenseDefaultValue: License = {
    id: -1,
    licenseId: '',
    state: '',
    type: '',
    einName: '',
    einId: '',
    companyName: '',
    expirationDate: '',
    lastVerifiedDate: '',
    createdDate: undefined,
    updatedDate: undefined,
    status: LicenseStatusEnum.ACTIVE,
    filepath: undefined,
};

export interface CannabizLicense {
    license_id: string;
    state: string;
    country: 'USA';
    country_subdivision?: string;
    status?: LicenseStatusEnum;
    $id?: string;
    $type?: string;
    id?: string;
    tradename?: string;
    license_description?: string;
    date_created?: string;
    last_updated?: string;
    last_verified?: string;
    expiration_date?: string;
}

export const mapLicense = (source: CannabizLicense): License => ({
    ...LicenseDefaultValue,
    licenseId: source.license_id,
    state: source.state,
    status: source.status || LicenseStatusEnum.ACTIVE,
    type: source.$type || '',
    expirationDate: source.expiration_date || '',
    lastVerifiedDate: source.last_verified || '',
});
