import { useFetchAuthedApi, AuthedApiResult, fetchAuthedApi } from './apiProvider';
import { Comment } from './types';

export const useGetComments = (alertId: string,
    forceUpdate: boolean = false): AuthedApiResult<Comment[]> => {
    return useFetchAuthedApi<Comment[]>({ path: `alerts/${alertId}/comments`, forceUpdate });
};

export const postComment = async (comment: Comment, token: string): Promise<void> => {
    await fetchAuthedApi<Comment>({
        token,
        path: `alerts/${comment.alert}/comments`,
        data: comment,
        method: 'POST',
    });
};
