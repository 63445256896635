import React, { useState } from 'react';
import { TooltipProps, TooltipPositionEnum, TooltipModeEnum } from './types/tooltip.types';
import { TooltipContainer, TooltipBox, TooltipText } from './tooltip.styled';

export const Tooltip = ({ children, content, position, mode, visible }: TooltipProps) => {
    const [isOpenState, setOpenState] = useState<boolean>(false);    
    const onHover = (mode: TooltipModeEnum | undefined): Boolean | void =>
        !mode || mode === TooltipModeEnum.HOVERED && setOpenState(visible === false ? false : !isOpenState);

    const onClick = (mode: TooltipModeEnum | undefined): Boolean | void =>
        mode!! === TooltipModeEnum.CONTROLLED && setOpenState(visible === false ? false : !isOpenState);

    return (<TooltipContainer
        onMouseEnter={() => onHover(mode)}
        onMouseLeave={() => onHover(mode)}
        onClick={() => onClick(mode)}>
        {children}
        <TooltipBox isOpen={isOpenState}
            position={position || TooltipPositionEnum.BOTTOM}>
            <TooltipText>{content}</TooltipText>
        </TooltipBox>
    </TooltipContainer>);
};
