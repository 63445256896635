import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '@components/modal/Modal';
import { ModalSaveButton } from '@components/modal/components/modal.styled';
import { Button } from 'rebass/styled-components';
import { User, Alert } from '@providers/types';
import { CommentTextarea } from './components/CommentTextarea';
import { escalateAlert } from '@providers/alert';
import { UserSelect } from '@components/user/UserSelect';
import { Box, Heading } from 'rebass/styled-components';
import { useAuth0 } from '@middleware/authorization';

export interface EscalateButtonProps {
  alert: Alert;
  onRefreshAlert: () => void;
}

const EscalateButton : React.FC<EscalateButtonProps> = ({alert, onRefreshAlert}) => {

    const { token } = useAuth0();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [body, setBody] = useState<string>('');
    const [newUser, setNewUser] = useState<User | undefined>(undefined);

    function canSubmit(user: User | undefined, body: string): user is User {
      return user !== undefined && body !== '';
    }

    function submit() {
      if(canSubmit(newUser, body)) {
        escalateAlert(alert, newUser, {title: '', body}, token)
          .then(() => setShowModal(false))
          .then(onRefreshAlert);
      }
    }

    return (
        <>
            <Modal visible={showModal} setVisible={setShowModal}
                header={<><FontAwesomeIcon icon={faExclamationTriangle} /> Escalate alert to another user</>}
                footer={<ModalSaveButton disabled={!canSubmit(newUser, body)} onClick={submit}>
                    <FontAwesomeIcon icon={faAngleDoubleUp} /> &nbsp; Escalate alert
                </ModalSaveButton>}>
                <Box pl='43px'>
                  {showModal && <>
                    <Heading htmlFor='comment'
                      fontSize='16px' marginTop='10px' marginBottom='10px'
                      >Escalate to</Heading>
                    <UserSelect onChange={setNewUser} />
                  </>}
                </Box>
                <CommentTextarea onChange={evt => setBody(evt.target.value)} />
            </Modal>
            <Button variant='secondary' mr={2} p={3} onClick={() => setShowModal(true)}>
                <FontAwesomeIcon icon={faExclamationTriangle} /> Escalate
            </Button>
        </>
    );
};

export { EscalateButton };
