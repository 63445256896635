export enum TooltipModeEnum {
    CONTROLLED,
    HOVERED,
}

export enum TooltipPositionEnum {
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export interface TooltipProps {
    children: React.ReactNode;
    content: React.ReactNode;
    mode?: TooltipModeEnum;
    position?: TooltipPositionEnum;
    visible?: boolean;
}
