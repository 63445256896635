import styled, { css } from 'styled-components';
import { Button } from 'rebass/styled-components';

const baseButtonCss = css`
    cursor: pointer;
    background-color: transparent;
    border: none;

    &:focus {
        outline: none;
    }

    &:hover {
        filter: brightness(90%);
    }
`;

export const DisableableButton = styled(Button) <{ disabled: boolean }>`
    background-color: ${({ theme, disabled }) => disabled ? theme.colors.gray : theme.colors.primary};
    color: ${({ theme, disabled }) => disabled ? theme.colors.text : theme.colors.background};
`;

export const StyledButton = styled.button`
    ${baseButtonCss}
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
`;

export const IconButton = styled.button`
    ${baseButtonCss}
    font-size: large;
`;
