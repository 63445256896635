import React from 'react';
import styled, { css } from 'styled-components';
import { Box, BoxProps } from 'rebass/styled-components';
import { HighlightColor } from '../TableContent';

const baseDataCss = css`
  padding: 20px 10px 20px 10px;
  text-align: center;
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
`;

interface TableProps
    extends BoxProps,
    Omit<React.TableHTMLAttributes<HTMLTableElement>, keyof BoxProps> { }

interface TableSectionProps
    extends BoxProps,
    Omit<React.HTMLAttributes<HTMLTableSectionElement>, keyof BoxProps> { }

interface TableDataCellProps
    extends BoxProps,
    Omit<React.TdHTMLAttributes<HTMLTableDataCellElement>, keyof BoxProps> { }

interface TableHeadCellProps
    extends BoxProps,
    Omit<React.TdHTMLAttributes<HTMLTableHeaderCellElement>, keyof BoxProps> { }

interface TableRowProps
    extends BoxProps,
    Omit<React.HTMLAttributes<HTMLTableRowElement>, keyof BoxProps> { }

const table: React.ComponentType<TableProps> = React.forwardRef((props, ref) => (
    <Box
        ref={ref}
        as='table'
        variant='table'
        {...props}
    />
));

const tableHeadCell: React.ComponentType<TableHeadCellProps> = React.forwardRef(
    (props, ref) => (
        <Box
            ref={ref}
            as='th'
            variant='th'
            {...props}
        />
    ),
);

const tableDataCell: React.ComponentType<TableDataCellProps> = React.forwardRef(
    (props, ref) => (
        <Box
            ref={ref}
            as='td'
            variant='td'
            {...props}
        />
    ),
);

export const TableRow: React.ComponentType<TableRowProps> = React.forwardRef((props, ref) => (
    <Box
        ref={ref}
        as='tr'
        variant='tr'
        {...props}
    />
));

export const TableHead: React.ComponentType<TableSectionProps> = React.forwardRef((props, ref) => (
    <Box
        ref={ref}
        as='thead'
        variant='thead'
        {...props}
    />
));

export const TableBody: React.ComponentType<TableSectionProps> = React.forwardRef((props, ref) => (
    <Box
        ref={ref}
        as='tbody'
        variant='tbody'
        {...props}
    />
));

export const Table = styled(table)`
  border-collapse: collapse;
  width: 100%;
`;

export const TableHeadCell = styled(tableHeadCell)`
    ${baseDataCss}
    white-space: nowrap;
    background-color: rgba(134,139,164,0.4);
    box-shadow: 0 1px 0 0 #22263A;
    font-style: ${({ theme }) => theme.fonts.title};
    font-size: 18px;

    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > svg {
            cursor: pointer;
        }
    }
`;

export const TableFilterRow = styled(TableRow)`
    background-color: rgba(134,139,164,0.25);
`;

export const TableBodyRow = styled(TableRow) <{ highlight?: HighlightColor }>`
    background-color: ${({ theme }) => theme.colors.backgroundRow};
    color: ${({ theme }) => theme.colors.text};
    cursor: ${({ onClick }) => onClick ? 'pointer' : 'unset'};
    &:hover {background-color: white;}

    ${({ highlight }) => highlight && css`
        font-weight: bold;
    `}

    ${({ highlight }) => highlight === HighlightColor.RED && css`
        background-color: ${({ theme }) => theme.colors.highlight.red.background};
        &:hover {background-color: ${({ theme }) => theme.colors.highlight.red.backgroundHover};}
    `}
    ${({ highlight }) => highlight === HighlightColor.ORANGE && css`
        background-color: ${({ theme }) => theme.colors.highlight.orange.background};
        &:hover {background-color: ${({ theme }) => theme.colors.highlight.orange.backgroundHover};}
    `}
    ${({ highlight }) => highlight === HighlightColor.YELLOW && css`
        background-color: ${({ theme }) => theme.colors.highlight.yellow.background};
        &:hover {background-color: ${({ theme }) => theme.colors.highlight.yellow.backgroundHover};}
    `}
    ${({ highlight }) => highlight === HighlightColor.WHITE && css`
        background-color: ${({ theme }) => theme.colors.highlight.white.background};
        &:hover {background-color: ${({ theme }) => theme.colors.highlight.white.backgroundHover};}
    `}
`;

export const TableDataCell = styled(tableDataCell)`
    ${baseDataCss}
`;

export const TableContainer = styled.div`
    width: 98%;
    overflow-x: auto;
    padding-bottom: 8vh;

    @media only screen and (min-width: 320px) and (max-width: 767px) {
        width: 93vw;
    }
`;

export const TableEmpty = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    background-color: ${({ theme }) => theme.colors.backgroundRow};
`;

export const MainContainer = styled.div`
    background-color: white;
    padding: 20px 10px;
`;
